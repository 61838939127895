import {Quill} from 'react-quill';
let Video = Quill.import('formats/video');
let Link = Quill.import('formats/link');

const ATTRIBUTES = ['height', 'width'];

// overflow: hidden;
// padding-top: 56.25%; /* 16:9*/
// position: relative;
// width: 100%;

class CustomVideo extends Video {
  static create(value) {
    let node = super.create(value);
    let div = document.createElement('div');
    div.setAttribute('class', 'autosize-container');
    div.appendChild(node);
    return div;
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static sanitize(url) {
    return Link.sanitize(url);
  }

  static value(domNode) {
    return domNode.getAttribute('src');
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
Video.blotName = 'video';
Video.className = 'ql-video';
Video.tagName = 'IFRAME';

export default CustomVideo;
