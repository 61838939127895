import React from 'react';
import Icon from '@ant-design/icons';

const ArrowLeftSVG = () => (
  <svg
    viewBox='0 0 24 24'
    fill='currentColor'
    width='1em'
    height='1em'
    xmlns='http://www.w3.org/2000/svg'>
    <polygon points='6,0 0,12 6,24 8,24 2,12 8,0' />
  </svg>
);

const ArrowRightSVG = () => (
  <svg
    viewBox='0 0 24 24'
    style={{transform: 'rotate(180deg)'}}
    fill='currentColor'
    width='1em'
    height='1em'
    xmlns='http://www.w3.org/2000/svg'>
    <polygon points='6,0 0,12 6,24 8,24 2,12 8,0' />
  </svg>
);
export function LeftArrow(props) {
  return <Icon component={ArrowLeftSVG} {...props} />;
}

export function RightArrow(props) {
  return <Icon component={ArrowRightSVG} {...props} />;
}
