import config from 'config.json';
import axios from 'axios';

export async function sendForm({data}) {
  return await axios({
    url: 'https://inficomp.ru/anketa/api/interview/send.php',
    method: 'POST',
    headers: config.headers,
    data: JSON.stringify({...data}),
  });
}

export async function sendSMS({phone}) {
  return axios({
    url: 'https://inficomp.ru/anketa/api/confirmationbysms/sendsms.php',
    method: 'POST',
    headers: config.headers,
    data: JSON.stringify({phone_number: phone}),
  });
}

export async function checkCodeSMS({phone, code, bearer}) {
  return axios({
    url: 'https://inficomp.ru/anketa/api/confirmationbysms/checkcodesms.php',
    method: 'POST',
    headers: {...config.headers, Authorization: `Bearer ${bearer}`},
    data: JSON.stringify({phone_number: phone, code: code}),
  });
}
