import React, {useEffect, useState, useRef} from 'react';
import {
  Affix,
  Button,
  Input,
  Space,
  Upload,
  Collapse,
  PageHeader,
  Badge,
  Image,
  List,
  Divider,
  Typography,
} from 'antd';
import {WechatOutlined} from '@ant-design/icons';
import {getUserEventsAPI} from '../Affiche/API';
import {getPastUserEventsAPI} from '../User/UserAPI';
import socket from 'Client/config/socket';
import {
  PaperClipOutlined,
  CloseCircleOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  SendOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import {useUserContext} from 'Client/config/context';
import FileDownload from 'js-file-download';
import {downloadFile} from './API';
import config from 'config.json';
import moment from 'moment';
import './style.css';

export function Chat() {
  const mobile = window.screen.width < 768 ? true : false;
  const [visibleRooms, setVisibleRooms] = useState('none');

  const [styles, setStyles] = useState(mobile ? 'chat__mobile' : 'chat__desktop');
  const [fullscreen, setFullscreen] = useState(false);

  let checkConnection = function (message) {
    console.log(message);
  };

  useEffect(() => {
    socket.on('check_connection', checkConnection);
    return function () {
      socket.off('check_connection', checkConnection);
    };
  }, []);

  function toggleVisible() {
    if (visibleRooms === 'none') {
      setVisibleRooms('block');
      if (mobile) document.body.style.overflow = 'hidden';
      socket.connect();
    } else {
      setVisibleRooms('none');
      if (mobile) document.body.style.overflow = '';
      socket.close();
    }
  }

  function toggleScreen() {
    if (fullscreen) {
      setFullscreen(false);
      setStyles('chat__desktop');
    } else {
      setStyles('chat__fullscreen');
      setFullscreen(true);
    }
  }

  return (
    <div>
      {visibleRooms === 'block' ? (
        <div className={`chat ${styles}`} style={{display: visibleRooms}}>
          <div className='chat__container'>
            <Space
              style={{
                width: '100%',
                background: 'white',
                borderBottom: '1px solid #eeeeee',
                justifyContent: 'flex-end',
                padding: '5px 5px 1px',
              }}>
              {!mobile ? (
                fullscreen ? (
                  <FullscreenExitOutlined
                    onClick={toggleScreen}
                    style={{fontSize: '1.5em', cursor: 'pointer'}}
                  />
                ) : (
                  <FullscreenOutlined
                    onClick={toggleScreen}
                    style={{fontSize: '1.5em', cursor: 'pointer'}}
                  />
                )
              ) : null}
              <CloseCircleOutlined
                onClick={toggleVisible}
                style={{fontSize: '1.5em', cursor: 'pointer'}}
              />
            </Space>
            <ChatListRooms fullscreen={false} />
          </div>
        </div>
      ) : null}
      <Affix className='chat__button_container'>
        <div onClick={toggleVisible} className='chat__button'>
          <WechatOutlined className='chat__icon' />
        </div>
      </Affix>
    </div>
  );
}

function ChatMenu(props) {
  const {user} = useUserContext();
  const [chatRooms, setChatRooms] = useState([]);
  const [archiveRooms, setArchiveRooms] = useState([]);
  const [supportRoom, setSupportRoom] = useState(null);
  const [countArhiveMessages, setCountArchiveMessages] = useState(0);

  const updateList = function (data) {
    setArchiveRooms((prev) => {
      let new_rooms = [...prev];
      for (let i in data) {
        let item = data[i];
        new_rooms.map((el) => {
          if (+el.id === +item.room_id) {
            el.count_message = item.count;
          }
          return el;
        });
      }
      return new_rooms;
    });
    setChatRooms((prev) => {
      let new_rooms = [...prev];
      for (let i in data) {
        let item = data[i];
        new_rooms.map((el) => {
          if (+el.id === +item.room_id) {
            el.count_message = item.count;
          }
          return el;
        });
      }
      return new_rooms;
    });
  };

  function updateNotification(data) {
    setArchiveRooms((prev) => {
      let new_rooms = [...prev];
      new_rooms.map((el) => {
        if (+el.id === +data.room_id) {
          el.count_message += 1;
        }
        return el;
      });
      return new_rooms;
    });
    setChatRooms((prev) => {
      let new_rooms = [...prev];
      new_rooms.map((el) => {
        if (+el.id === +data.room_id) {
          el.count_message += 1;
        }
        return el;
      });
      return new_rooms;
    });
  }

  function preFormatList(lst) {
    lst.map((item) => {
      item.count_message = 0;
      return item;
    });
  }

  useEffect(() => {
    Promise.all([
      getUserEventsAPI({limit: 0, offset: 0}),
      getPastUserEventsAPI({limit: 0, offset: 0}),
    ]).then((res) => {
      preFormatList(res[0].data);
      preFormatList(res[1].data);
      setChatRooms(res[0].data);
      setArchiveRooms(res[1].data);
      socket.on('course:list_notifications', updateList);
      socket.on('course:notification', updateNotification);
      socket.on('support:notification', setSupportRoom);

      socket.emit('course:get_notifications');
      socket.emit(`support:get_notification`);
    });
    return function () {
      socket.off('course:list_notifications', updateList);
      socket.off('course:notification', updateNotification);
      socket.off('support:notification', setSupportRoom);
    };
  }, []);

  useEffect(() => {
    let lst = archiveRooms.filter((el) => {
      if (el.count_message > 0) return true;
      return false;
    });
    setCountArchiveMessages(lst.length);
  }, [archiveRooms]);

  function onClick(type) {
    if (typeof props.onClick === 'function') {
      props.onClick(type);
    }
  }

  return (
    <>
      <div
        className='chat__support_header'
        onClick={() => onClick({type: 'support', room: user.id, title: 'Поддержка'})}>
        Связаться с поддержкой <Badge count={supportRoom && supportRoom.count} />
      </div>
      <div
        className='chat__support_header'
        onClick={() => onClick({type: 'chatgpt', room: user.id, title: 'Личный помощник'})}>
        Личный помощник
      </div>
      {Boolean(chatRooms.length) && (
        <List
          style={{background: 'white', cursor: 'pointer'}}
          dataSource={chatRooms}
          renderItem={(item) => (
            <List.Item
              className='chat__list_item'
              key={item.id}
              onClick={() => onClick({type: 'course', room: item.id, title: item.name})}
              style={{padding: '1em'}}>
              <Badge
                size='small'
                offset={[10, 0]}
                count={item.count_message ? item.count_message : 0}>
                {item.name}
              </Badge>
            </List.Item>
          )}
        />
      )}
      {Boolean(archiveRooms.length) && (
        <Collapse style={{background: 'none', border: 'none'}}>
          <Collapse.Panel
            header={
              <>
                <Typography.Text>Архивный чат</Typography.Text>{' '}
                <Badge count={countArhiveMessages} />
              </>
            }
            className='chat__collapse_custom'>
            <List
              style={{background: 'white', cursor: 'pointer'}}
              dataSource={archiveRooms}
              renderItem={(item) => (
                <List.Item
                  className='chat__list_item'
                  key={item.id}
                  onClick={() => onClick({type: 'course', room: item.id, title: item.name})}
                  style={{padding: '1em'}}>
                  <Badge
                    size='small'
                    offset={[10, 0]}
                    count={item.count_message ? item.count_message : 0}>
                    {item.name}
                  </Badge>
                </List.Item>
              )}
            />
          </Collapse.Panel>
        </Collapse>
      )}
    </>
  );
}

function ChatListRooms() {
  const [room, setRoom] = useState(null);

  function joinRoom(obj) {
    setRoom(obj);
  }

  function leaveRoom() {
    socket.emit(`${room.type}:leave_room`, room.room);
    setRoom(null);
  }

  return room === null ? (
    <ChatMenu onClick={joinRoom} />
  ) : (
    <Room isBack={true} data={room.room} room={room.type} title={room.title} onBack={leaveRoom} />
  );
}

export function Room(props) {
  const type_room = props.room;
  const {user} = useUserContext();
  const [messages, setMessages] = useState([]);
  const [uploadListFiles, setUploadListFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [typingUsers, setTypingUsers] = useState(null);

  const ref = useRef();

  let onSystemMessage = function (msg) {
    setMessages((prev) => {
      return [...prev, msg];
    });
    try {
      ref.current.scrollTop = ref.current.scrollHeight;
    } catch {
      console.log('SCROLL ERROR');
    }
  };

  let onGetListMessage = function (messages) {
    let date = null;
    for (let i = 0; i < messages.length; i++) {
      let message_date = moment(messages[i].datetime).format('DD/MM/YYYY');
      messages[i].new_day = false;
      if (date !== message_date) {
        date = message_date;
        messages[i].new_day = message_date;
      }
    }
    setMessages(messages);
    socket.on(`${type_room}:typing`, onTypingListUsers);
    socket.once(`${type_room}:system_message`, onSystemMessage);
    socket.on(`${type_room}:edited_message`, onEditedMessage);
    try {
      ref.current.scrollTop = ref.current.scrollHeight;
    } catch {
      console.log('SCROLL ERROR');
    }
  };

  let onEditedMessage = function(msg){
    setMessages(prev => {
      let new_messages = [...prev]
      for(let i = 0; i < new_messages.length; i++){
          if(new_messages[i]['id'] === msg['id']){
              new_messages[i].message = msg.message
          }
      }
      return new_messages
    });
}

  let onGetMessage = function (usr_message) {
    setMessages((prev) => {
      return [...prev, usr_message];
    });
    socket.emit(`${type_room}:read_message`, {room: props.data});
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  let onTypingListUsers = function (data) {
    setUsers((prev) => {
      let existUser = false;
      let new_users = [...prev];

      for (let i = 0; i < prev.length; i++) {
        if (new_users[i].id === data.id) {
          existUser = true;
          new_users[i] = data;
        }
      }
      if (!existUser) {
        new_users.push(data);
      }
      return new_users;
    });
  };

  let onDeleted = function(data){
    setMessages(prev => {
      let new_messages = [...prev].filter(el => {
        if (el.id === data.id){
          return false;
        }
        return true;
      });
      return new_messages;
    })
  }

  useEffect(() => {
    let typing_users = [];
    let tu = null;
    users.map((el) => {
      if (el.typing) {
        typing_users.push(el.name);
      }
      return el;
    });
    if (typing_users.length) {
      tu = typing_users.join(', ');
      if (typing_users.length === 1) {
        tu += ' печатает...';
      } else {
        tu += ' печатают...';
      }
    }
    setTypingUsers(tu);
  }, [users]);

  useEffect(() => {
    socket.on(`${type_room}:list_messages`, onGetListMessage);
    socket.on(`${type_room}:get_message`, onGetMessage);
    socket.on(`${type_room}:deleted`, onDeleted);
    socket.emit(`${type_room}:join_room`, props.data);
    return function () {
      socket.off(`${type_room}:deleted`, onDeleted);
      socket.off(`${type_room}:list_messages`, onGetListMessage);
      socket.off(`${type_room}:get_message`, onGetMessage);
      socket.off(`${type_room}:typing`, onTypingListUsers);
      socket.off(`${type_room}:edited_message`, onEditedMessage);
    };
  }, []);

  function uploadFiles(file) {
    setUploadListFiles(file.fileList);
  }

  function deleteMessage(id){
    socket.emit(`${type_room}:delete_message`, {
      room: props.data, id: id, type_room: props.room
    });
    setMessages(prev => {
      let new_messages = [...prev].filter(el => {
        if (el.id === id){
          return false;
        }
        return true
      });
      return new_messages;
    })
  }

  return (
    <div className='chat__area'>
      <PageHeader
        subTitle={
          <>
            <Typography.Text>{props.title}</Typography.Text>
            <br></br>
            {typingUsers && (
              <span style={{position: 'absolute', bottom: '2px'}}>{typingUsers}</span>
            )}
          </>
        }
        style={{background: 'white', padding: '1em 24px'}}
        onBack={props.isBack ? () => props.onBack(props.data): false}
      />
      <div ref={ref} className='chat__messages' style={{height: '100%'}}>
        <div>
          <ul className='chat__messages_items'>
            {messages.map((el) => {
              if (el.type === 'system') {
                return <SystemMessage data={el} />;
              }
              return (
                <>
                  {el.new_day && (
                    <Divider style={{marginBottom: 0}}>
                      <Typography type='secondary' style={{fontSize: '12px'}}>
                        {el.new_day}
                      </Typography>
                    </Divider>
                  )}
                  <Message
                    user={el}
                    key={el.id}
                    onDeleteMessage={deleteMessage}
                    self={Number(user.id) === Number(el.id_user) ? true : false}
                  />
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div>
        <Upload
          method='POST'
          accept='.jpg,.png,.jpeg,.pdf,.xml,.doc,.docx,.xlsx,.xls,.xlsm,.txt,.csv'
          action={`${config.socketURL}/upload/${props.data}`}
          onChange={uploadFiles}
          customRequest={false}
          maxCount={3}
          multiple
          fileList={uploadListFiles}>
          <Button
            icon={<PaperClipOutlined />}
            style={{
              border: 'none',
              position: 'absolute',
              zIndex: '10',
              bottom: '5px',
              right: '14px',
            }}
          />
        </Upload>
        <div style={{position: 'relative'}}>
          <InputArea
            onTyping={(typing) => {
              socket.emit(`${type_room}:typing`, {room: props.data, typing});
            }}
            onEnter={(message) => {
              let files = [];
              if (uploadListFiles.length) {
                uploadListFiles.map((file) => {
                  if (file.status === 'done')
                    files.push({
                      name: file.name,
                      type: file.type,
                      url: file.response.url,
                    });
                });
              }
              if (message.length || uploadListFiles.length) {
                socket.emit(`${type_room}:send_message`, {
                  room: props.data,
                  message: message,
                  files: files,
                });
                socket.emit(`${type_room}:typing`, {room: props.data, typing: false});
                setUploadListFiles([]);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

function InputArea(props) {
  const [timeoutID, setTimeoutID] = useState(null);
  const [message, setMessage] = useState('');

  let restartTyping = function () {
    onTyping(false);
    setTimeoutID(null);
  };

  function isEmptyMessage() {
    if (message === null || message.trim() === '') {
      return true;
    }
    return false;
  }

  function typing(e) {
    clearTimeout(timeoutID);
    if (timeoutID === null) {
      onTyping(true);
      setTimeoutID(setTimeout(restartTyping, 2500));
    } else {
      setTimeoutID(setTimeout(restartTyping, 2500));
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      onEnter(message);
    }
  }

  function onChange(text) {
    if (typeof props.onChange === 'function') {
      props.onChange(text);
    }
    setMessage(text);
  }

  function onEnter(message) {
    if (typeof props.onEnter === 'function') {
      setTimeoutID((prev) => {
        clearTimeout(prev);
        return null;
      });
      props.onEnter(!isEmptyMessage() ? message : '');
    }
    setMessage('');
  }

  function onTyping(typing) {
    if (typeof props.onEnter === 'function') {
      props.onTyping(typing);
    }
  }

  return (
    <>
      <Button
        style={{position: 'absolute', top: '5px', zIndex: '10', right: '5px'}}
        type='primary'
        shape='circle'
        icon={<SendOutlined />}
        onClick={() => onEnter(message)}
      />
      <Input.TextArea
        value={message}
        onChange={(e) => onChange(e.target.value)}
        style={{background: 'white', padding: '5px 3em 5px 1em'}}
        bordered={false}
        maxLength={350}
        autoSize={{minRows: 3, maxRows: 6}}
        onKeyDown={typing}
      />
    </>
  );
}

function Message(props) {
  const [time, setTime] = useState(null);
  let intervalID = null;
  let token_sec_v1 = localStorage.getItem('token_sec_v1');

  useEffect(() => {
    clearInterval(intervalID);
    if (props.user.datetime) {
      setTime(moment(props.user.datetime).format('HH:mm'));
    }
  }, []);

  function deleteMessage(){
    if(typeof props.onDeleteMessage === 'function'){
      props.onDeleteMessage(props.user.id);
    }
  }

  return (
    <li
      data-id={props.user.id}
      className={`chat__messages_item ${props.self ? 'chat__user_message' : ''}`}>
      {props.user.avatar && !props.self && (
        <img alt='user avatar' className='chat__message_avatar' src={props.user.avatar} />
      )}
      
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div>
          <Space size={8}>
            <Typography.Text type='secondary' style={{margin: '0 0 0 5px'}}>
              {props.user.fullname}
            </Typography.Text>
            {token_sec_v1 && <DeleteOutlined onClick={deleteMessage} style={{color: 'red', cursor: 'pointer'}} />}
          </Space>
          <div className={`chat__message ${props.self ? 'chat__user_self_message' : ''}`}>
            <Typography.Text>{props.user.message}</Typography.Text>
            {props.user.files &&
              props.user.files.length !== 0 &&
              props.user.files.map((el) => {
                return <Files key={el.uid} file={el} />;
              })}
          </div>
        </div>
        {time && <Typography.Text style={{flex: 1, order: props.self ? 0: 1, alignSelf: 'flex-end'}} type='secondary'>{time}</Typography.Text>} 
      </div>
      
    </li>
  );
}

function SystemMessage(props) {
  return (
    <li className='chat__system_message'>
      <Typography.Text secondary>{props.data.message}</Typography.Text>
    </li>
  );
}

function Files(props) {
  const imageMimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/vnd.microsoft.icon',
    'image/vnd.wap.wbmp',
    'image/webp',
  ];
  function download() {
    downloadFile(props.file.url).then((res) => {
      try {
        FileDownload(res.data, props.file.name);
      } catch (e) {
        console.log(e);
      }
    });
  }
  return imageMimeTypes.indexOf(props.file.type) !== -1 ? (
    <Image style={{maxWidth: '300px'}} src={`${config.socketURL}/${props.file.url}`} />
  ) : (
    <Typography.Link onClick={download}>{props.file.name}</Typography.Link>
  );
}
