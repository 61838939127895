import {io} from 'socket.io-client';
import config from 'config.json';

export default (function socket(token_storage) {
  const URL = config.socketURL;
  const existingTokens = JSON.parse(localStorage.getItem(token_storage));
  let options = {
    autoConnect: false,
  };
  if (existingTokens) {
    options['extraHeaders'] = {Authorization: `Bearer ${existingTokens.token}`};
  }
  return io(URL, options);
})('token');
