import axios from 'axios';
import config from 'config.json';

export async function uploadFile(data) {
  let form_data = new FormData();
  form_data.append('file', data.file);
  form_data.append('room', data.room);

  return await axios.post(`${config.socketURL}/upload/`, form_data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function downloadFile(url) {
  let form_data = new FormData();
  form_data.append('url', url);
  console.log(url);
  return axios.post(`${config.socketURL}/download/`, form_data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'blob',
  });
}
