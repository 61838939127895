import React, {useState, useEffect} from 'react';
import {Typography, Row, Carousel} from 'antd';
import {AfficheList} from './AfficheList';
import {Projects} from './Projects';
import {ColPrivilegeItem} from '../User/UserPrivilege';
import {getPriviledgeAPI} from '../User/UserAPI';
import {getPartnersEvents} from './API';
import {LeftArrow, RightArrow} from 'Base/Icons/Arrows';
import {ExternalCard} from 'Base/Card';
import {RecommendAppsDrawer} from '../MobileAppsLink/DrawerApp';
import {Panel} from '../Layout';
import {Course} from '../Shop/Course';
import '../style.css';

export function UserAfficheBoard() {
  const [extEvents, setExtEvents] = useState(null);
  const [privilege, setPrivilege] = useState(null);

  useEffect(() => {
    Promise.all([getPartnersEvents(), getPriviledgeAPI({limit: 20, offset: 0})]).then((res) => {
      setExtEvents(res[0].data);
      setPrivilege(res[1].data);
    });
  }, []);

  function Arrow({component: Component, ...props}) {
    const {className, style, onClick} = props;
    return (
      <Component
        className={className}
        style={{
          ...style,
          ...props.styleCSS,
          display: 'block',
          fontSize: '2em',
          color: 'rgba(46,150,254,.7)',
          zIndex: 50,
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <div style={{margin: '0 auto 25px'}}>
      <Projects fluid={false} postLoaded={false} title='Афиша' />
      <Panel title='Мои мероприятия' url='/events/'>
        <AfficheList />
      </Panel>
      <Panel title='Курсы' url='/shop/course/'>
        <Course />
      </Panel>

      {extEvents && extEvents.length !== 0 &&
        <section style={{marginTop: '2em'}}>
          <Typography.Title level={2}>Полезное</Typography.Title>
          <Carousel
            style={{margin: '0 -10px'}}
            infinite={false}
            slidesToShow={4}
            dots={false}
            autoplay
            arrows
            nextArrow={<Arrow styleCSS={{right: '0px'}} component={RightArrow}></Arrow>}
            prevArrow={<Arrow styleCSS={{left: '-10px'}} component={LeftArrow}></Arrow>}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  infinite: true,
                  arrows: true,
                  dots: false,
                },
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 3,
                  infinite: true,
                  arrows: false,
                  dots: true,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  infinite: true,
                  arrows: false,
                  dots: true,
                },
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  infinite: true,
                  arrows: false,
                  dots: true,
                },
              },
            ]}
            dotPosition='top'>
            {extEvents.map((el) => {
              return <ExternalCard key={el.id} url={el.url} img={el.image} />;
            })}
          </Carousel>
        </section>
      }

      {privilege && privilege.length ? (
        <section style={{marginTop: '2em'}}>
          <Typography.Title level={2}>Привилегии</Typography.Title>
          <Row gutter={[20, 20]}>
            {window.screen.width < 768 ? (
              <Carousel infinite={false} dotPosition='top' style={{width: '100%', height: '100%'}}>
                {privilege.map((el) => {
                  return (
                    <ColPrivilegeItem
                      key={el.id}
                      id={el.id}
                      image={el.image}
                      name={el.name}
                      desc={el.description}
                      descShort={el.short_description}
                      isRegister={el.is_registered}
                    />
                  );
                })}
              </Carousel>
            ) : (
              privilege &&
              privilege.map((el) => {
                if (el.short_description.length > 70) {
                  el.short_description = el.short_description.slice(0, 70) + '...';
                }
                return (
                  <ColPrivilegeItem
                    key={el.id}
                    id={el.id}
                    image={el.image}
                    name={el.name}
                    desc={el.description}
                    descShort={el.short_description}
                    isRegister={el.is_registered}
                  />
                );
              })
            )}
          </Row>
        </section>
      ) : null}
      {window.screen.width < 500 && <RecommendAppsDrawer />}
    </div>
  );
}
