import React from 'react';
import Icon from '@ant-design/icons';

const CloseSVG = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='currentColor'
    width='1em'
    height='1em'>
    <path
      id='times-solid'
      d='M6.845,5.781,5.781,6.845l6.5,6.5-6.5,6.5L6.845,20.91l6.5-6.5,6.5,6.5,1.064-1.064-6.5-6.5,6.5-6.5L19.846,5.781l-6.5,6.5Z'
      transform='translate(-5.781 -5.781)'
      fill='#888fa0'
    />
  </svg>
);
export function CloseIcon(props) {
  return <Icon component={CloseSVG} {...props} />;
}
