import React, {useState} from 'react';
import {Form, Row, Col, Typography, Radio, Input} from 'antd';
import {FirstVariant} from './FirstVariant';
import {SecondVariant} from './SecondVariant';
import questions from './question.map.json';
import {ModalConfidence, ModalSucess, ModalError} from './ModalConfidece';
import {sendForm, sendSMS} from './FormAPI';
import { SelfEmployed } from './SelfEmployed';

const BeginForm = ({onFirstChange, value, questions}) => {
  const [dataConfidense, setDataConfidense] = useState({visible: false, status: false});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function handleSubmitForm(data) {
    let send = {};
    send['type_person'] = data['type_person'];
    send['name'] = data['name'];
    [send['city'], send['id_region']] = data['city'].split(':');
    send['id_region'] = send['id_region'].slice(0, 2);
    send['surname'] = data['surname'];
    send['last_name'] = data['last_name'];
    send['email'] = data['email'];
    send['phone_number'] = '7' + data['phone_number'];
    let date = data['birthday']._d;
    send['birthday'] = String(
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
    );
    if (data['type_person'] === 1 || data['type_person'] === 13) {
      send['inn'] = data['inn'];
      // send['answers'] = {
      //     '1': data['interest'].join(';'),
      //     '2': data['usefull'],
      //     '4': data['gov_info_help'],
      //     '6': data['is_training'],
      //     '8': data['is_invite_event'],
      //     '10': data['learning_topics'].join(';')
      // }
      // send['interests'] = data['interest'].concat(data['learning_topics'])
    } else {
      send['inn'] = '';
      // send['answers'] = {
      //     '1': data['interest-s'].join(';'),
      //     '3': data['is_want_business'],
      //     '5': data['is_family_business'],
      //     '7': data['is_easy_business'],
      //     '9': data['is_need_knowledge'].join(';'),
      //     '11': data['knowledge_for_business'].join(';'),
      //     '12': data['is_gov_help_business'],
      //     '13': data['is_interest']
      // }
      // send['interests'] = data['interest-s']
    }
    setLoading(true);
    sendForm({data: send})
      .then((res) => {
        let token = res.data.token;
        setLoading(false);
        if (res.data['status']) {
          sendSMS({phone: send['phone_number']})
            .then((res) => {
              if (res.data.status === true) {
                setDataConfidense({
                  visible: true,
                  status: false,
                  phone_number: send['phone_number'],
                  token: token,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setError({text: 'Ваш номер уже существует. Войдите пожалуйста в систему.'});
          setLoading(false);
        }
      })
      .catch((err) => {
        setError({text: 'Войдите в личный кабинет'});
        setLoading(false);
      });
  }

  function onCloseConfidence(status) {
    setDataConfidense({visible: false, status: status, phone_number: null});
  }

  return (
    <Form
      name='global_form'
      onFinish={(e) => {
        handleSubmitForm(e);
        // console.log(e);
      }}>
      <Typography.Title level={4}>{questions.type_person.title}</Typography.Title>
      <Input.Group>
        <Form.Item name='type_person' rules={[{required: true, message: 'Упущено поле'}]}>
          <Radio.Group
            onChange={(e) => {
              onFirstChange(e.target.value);
            }}>
            <Radio.Button
              style={{borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px'}}
              value={1}>
              Юридическое лицо
            </Radio.Button>
            <Radio.Button
              style={{borderTopRightRadius: '4px', borderBottomRightRadius: '4px'}}
              value={0}>
              Физичекое лицо
            </Radio.Button>
            <Radio.Button
              style={{borderTopRightRadius: '4px', borderBottomRightRadius: '4px'}}
              value={13}>
              Самозанятый
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Input.Group>
      {/* FAKE SHOW CODE CONFIDENCE <Button onClick={()=> {setDataConfidense({visible: true})}}>Show</Button> */}
      <ModalConfidence
        data={dataConfidense}
        onClose={(status) => onCloseConfidence(status)}></ModalConfidence>
      {dataConfidense.status ? <ModalSucess></ModalSucess> : null}
      {error ? (
        <ModalError
          error={error.text}
          onClose={() => {
            setError(null);
          }}></ModalError>
      ) : null}
      {value === 1 ? <FirstVariant questions={questions} loading={loading} /> : null}
      {value === 0 ? <SecondVariant questions={questions} loading={loading} /> : null}
      {value === 13 ? <SelfEmployed questions={questions} loading={loading} /> : null}
    </Form>
  );
};

export function MainForm() {
  const [value, setValue] = useState(false);

  return (
    <div className='form__wrapper'>
      <Row>
        <Col sm={{span: 18, offset: 3}} xs={{span: 24}}>
          <BeginForm
            questions={questions}
            onFirstChange={(choice_form) => {
              setValue(choice_form);
            }}
            value={value}></BeginForm>
        </Col>
      </Row>
    </div>
  );
}
