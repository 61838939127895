import axios from 'Client/config/axios';

export async function getAPIuserEvents({limit, offset}) {
  let data = {limit: Number(limit), offset: Number(offset)};
  return await axios.post('/user/getmyevents.php', JSON.stringify(data));
}

export async function getPastUserEventsAPI({limit, offset}) {
  let data = {limit: Number(limit), offset: Number(offset)};
  return await axios.post('/user/getmypastevents.php', JSON.stringify(data));
}

export async function isExistPhotoUserAPI() {
  return await axios.post('/user/existuseravatar.php');
}

export async function uploadPhotoUserAPI({photo}) {
  console.log('FILE ', photo);
  let form_data = new FormData();
  form_data.append('image', photo);
  axios.post('/user/updateuseravatar.php', form_data, {
    headers: {'Content-type': 'multipart/form-data'},
  });
}

export async function infoUserAPI() {
  return await axios.post('/user/getinfo.php');
}

export async function infoUserInterestsAPI() {
  return await axios.post('/user/getinterests.php');
}

export function getUserScore() {
  return axios.post('/user/getballs.php');
}

export function getPriviledgeAPI({limit, offset}) {
  let data = {limit: Number(limit), offset: Number(offset)};
  return axios.post('/privilege/getprivilege.php', JSON.stringify(data));
}

export function registerPrivilegeAPI({id}) {
  let data = {id_privilege: Number(id)};
  return axios.post('/privilege/registrationforprivilege.php', JSON.stringify(data));
}

export function resaveUserAPI(data) {
  if (data.learning_topics === undefined) {
    data['learning_topics'] = [];
  }
  let send = {
    id_region: 13,
    city: '',
    name: String(data.name),
    surname: String(data.surname),
    last_name: String(data.last_name),
    interests: [...data.interests, ...data.learning_topics],
  };
  return axios.post('/user/setinfo.php', JSON.stringify(send));
}
