import React, {useState, useEffect} from 'react';
import {Col, Row, Typography, Button} from 'antd';
import {EventCard} from 'Base/Card';
import {useList} from 'react-use';
import {getUserEventsAPI} from './API';
import {formatDate} from './utils/functions';
import PropTypes from 'prop-types';

AfficheList.propTypes = {
  postLoad: PropTypes.bool,
};

export function AfficheList(props) {
  const limit = 9;
  const [list, {push}] = useList([]);
  const [offset, setOffset] = useState(0);
  const [postLoad, setPostLoad] = useState(true);
  let requestAPI = getUserEventsAPI;

  if(props.customRequest){
    requestAPI = props.customRequest
  }

  useEffect(() => {
    requestAPI({limit: limit, offset: offset}).then((res) => {
      if (res.data.length < limit) setPostLoad(false);
      res.data.map((el) => {
        el.date = formatDate({begin: el.date_time_start, end: el.date_time_end});
        el.description = '\nМесто: ' + el.location;
        el.balls = convert_balls(el.give_balls, el.cost_balls);
        return el;
      });
      push(...res.data);
    });
  }, [offset]);

  function convert_balls(give, cost) {
    return !+give ? +give - +cost : +give;
  }

  return (
    <>
      <Row gutter={[20, 20]}>
        {list.map((el) => {
          return (
            <Col key={el.id} md={8} sm={12} xs={24}>
              <EventCard
                leftTopBall={el.balls}
                title={el.name}
                img={el.images}
                url={`/event/${el.id}`}
                description={<Typography.Paragraph>{el.date}</Typography.Paragraph>}
              />
            </Col>
          );
        })}
      </Row>
      {postLoad && props.postLoad && (
        <Button size='large' style={{width: '100%'}} onClick={() => setOffset(offset + 9)}>
          Загрузить еще
        </Button>
      )}
    </>
  );
}