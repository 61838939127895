import React, {forwardRef} from 'react';
import ReactInputMask from 'react-input-mask';
import {KeyOutlined} from '@ant-design/icons';
import {Input} from 'antd';

export default forwardRef((props, ref) => {
  return (
    <ReactInputMask {...props}>
      {(inputProps) => (
        <Input
          size='large'
          prefix={<KeyOutlined style={{fontSize: 20}} />}
          {...inputProps}
          ref={ref}
          disabled={props.disabled ? props.disabled : null}
        />
      )}
    </ReactInputMask>
  );
});
