import React from 'react';
import {Typography} from 'antd';
import {Link} from 'react-router-dom';
import {ScoreTag} from '../Tags';
import PropTypes from 'prop-types';
import './style.css';

EventCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  leftTopBall: PropTypes.number,
  url: PropTypes.string,
  img: PropTypes.string,
};

export default function EventCard(props) {
  return (
    <div className='affiche__container'>
      <div className='affiche__preview'>
        <Link to={props.url && props.url}>
          <img className='affiche__image' src={props.img && props.img} alt={props.title} />
        </Link>
        {/* {props.leftTopBall && <ScoreTag score={props.leftTopBall} />} */}
      </div>
      {props.title && <Typography.Title level={4}>{props.title}</Typography.Title>}
      {props.description && props.description}
    </div>
  );
}
