import React, {useState, useEffect} from 'react';
import {Typography, Space, Button, Row, Modal, Col, notification} from 'antd';
import {Loader} from 'Base/Loader/Loader';
import {getPriviledgeAPI, registerPrivilegeAPI} from './UserAPI';

export function ColPrivilegeItem({id, image, name, desc, descShort, isRegister}) {
  const [modalInfo, setModalInfo] = useState(false);
  const [activatePrivilege, setActivatePrivilege] = useState(isRegister);

  const notificationModal = (type) => {
    notification[type]({
      message: 'Бонус активирован',
      description: `Ваша заявка будет рассмотрена в ближайшее время`,
    });
  };

  function registerPrivilege(id) {
    registerPrivilegeAPI({id}).then(() => {
      notificationModal('success');
      setActivatePrivilege(true);
      setModalInfo(false);
    });
  }

  return (
    <>
      <Col xs={24} md={8}>
        <Row style={{minHeight: '236px', background: '#E7EBF3', height: '100%'}}>
          <Col
            flex='1 1 100px'
            style={{
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img alt='privilege' src={image} style={{maxWidth: '100%'}}></img>
          </Col>
          <Col flex='1 1 100px'>
            <Space
              direction='vertical'
              style={{
                padding: '20px',
                height: '100%',
                width: '100%',
                justifyContent: 'space-evenly',
                background: '#F0F3F8',
              }}>
              <Typography.Title level={4}>{name}</Typography.Title>
              <Typography.Paragraph ellipsis={{rows: 4, expandable: false}}>
                {descShort}
              </Typography.Paragraph>
              <Button onClick={() => setModalInfo(true)}>Подробнее</Button>
            </Space>
          </Col>
          <Modal
            footer={null}
            bodyStyle={{padding: 0}}
            visible={modalInfo}
            onOk={() => setModalInfo(false)}
            onCancel={() => setModalInfo(false)}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                background: '#F0F3F8',
                padding: '32px',
              }}>
              <img alt='privilege' src={image} style={{width: '200px', height: '200px'}} />
            </div>
            <div style={{padding: '16px'}}>
              <Typography.Title level={4}>Описание</Typography.Title>
              <Typography.Paragraph style={{whiteSpace: 'pre-wrap'}}>{desc}</Typography.Paragraph>

              <Button
                disabled={activatePrivilege}
                style={{width: '100%'}}
                type='primary'
                onClick={() => registerPrivilege(id)}>
                {!activatePrivilege ? 'Активировать' : 'Активирован'}
              </Button>
            </div>
          </Modal>
        </Row>
      </Col>
    </>
  );
}

export function UserPrivilege() {
  const [privilege, setPrivilege] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loadVisible, setLoadVisible] = useState(true);
  const [pageLoad, setPageLoad] = useState(true);
  const limit = 32;

  useEffect(() => {
    setPageLoad(true);
    getPriviledgeAPI({limit: limit, offset: offset}).then((res) => {
      if (res.data.length < limit) {
        setLoadVisible(false);
        setPrivilege(res.data);
      }
      setPageLoad(false);
    });
  }, [offset]);
  return (
    <div style={{margin: '70px auto'}}>
      {pageLoad && <Loader />}
      <Typography.Title level={1}>Привилегии</Typography.Title>
      <Row gutter={[16, 16]}>
        {privilege.length
          ? privilege.map((el) => {
              return (
                <ColPrivilegeItem
                  key={el.id}
                  id={el.id}
                  image={el.image}
                  name={el.name}
                  desc={el.description}
                  descShort={el.short_description}
                  isRegister={el.is_registered}
                />
              );
            })
          : null}
      </Row>
      {loadVisible && (
        <Button size='large' style={{width: '100%'}} onClick={() => setOffset(offset + limit)}>
          Загрузить еще
        </Button>
      )}
    </div>
  );
}
