import React from 'react';
import {Space, Typography} from 'antd';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './style.css';

Panel.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

export default function Panel(props) {
  return (
    <section className='panel__container'>
      <Space className='title__link'>
        {props.title && <Typography.Title level={2}>{props.title}</Typography.Title>}
        {props.url && <Link to={props.url}>ПОКАЗАТЬ ВСЕ &#8594;</Link>}
      </Space>
      {props.children}
    </section>
  );
}