import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

ExternalCard.propTypes = {
  url: PropTypes.string,
  img: PropTypes.string,
};

export default function ExternalCard(props) {
  return (
    <div className='affiche__container affiche_external_card'>
      <div className='affiche__preview'>
        <a href={props.url && props.url}>
          <img className='affiche__image' src={props.img && props.img} />
        </a>
      </div>
    </div>
  );
}
