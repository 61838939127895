import React from 'react';
import Icon from '@ant-design/icons';

const SuccessSVG = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='currentColor'
    width='1em'
    height='1em'>
    <path
      id='check-solid'
      d='M21.822,6.281,8.834,19.269,3.362,13.8l-1.08,1.08L8.294,20.89l.54.517.54-.517L22.9,7.362Z'
      transform='translate(-2.281 -6.281)'
      fill='#295adf'
    />
  </svg>
);
export function SuccessIcon(props) {
  return <Icon component={SuccessSVG} {...props} />;
}
