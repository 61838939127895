import {createContext, useContext, useState} from 'react';
import {infoUserAPI, getUserScore} from '../components/User/UserAPI';
import {useLocation} from 'react-router-dom';
import axios from './axios';

export const AuthContext = createContext();
export const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export function UserContextProvider(props) {
  const [user, setUserSettings] = useState(null);
  const [theme, setTheme] = useState('primary');

  function infoUser() {
    return Promise.all([infoUserAPI(), getUserScore()]).then((res) => {
      let user = res[0].data;
      let user_score = res[1].data;
      let result_user_data = {
        id: user.id,
        name: user.name,
        surname: user.surname,
        last_name: user.last_name,
        email: user.email,
        score: user_score.balls,
        avatar: user.avatar,
      };

      setUserSettings(result_user_data);
      return Promise.resolve(result_user_data);
    });
  }

  return (
    <UserContext.Provider value={{user, setUserSettings, theme, setTheme, infoUser}}>
      {props.children}
    </UserContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthContextProvider(props) {
  const authObject = JSON.parse(localStorage.getItem('token'));
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(
    authObject && authObject.token ? true : false,
  );

  const setAuth = (boolean) => {
    setIsAuthenticated(boolean);
  };

  function Authorization(key) {
    localStorage.setItem('token', JSON.stringify({token: key}));
    setAuth(true);
  }

  function isExpireUsersTokenAPI(token) {
    return axios.post('/user/isexpiretoken.php', JSON.stringify({token}));
  }

  async function checkAuthToken() {
    localStorage.setItem('utm', location.pathname);
    return isExpireUsersTokenAPI(authObject.token);
  }

  return (
    <AuthContext.Provider value={{isAuthenticated, setAuth, checkAuthToken, Authorization}}>
      {props.children}
    </AuthContext.Provider>
  );
}
