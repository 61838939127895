import React, {useState, useEffect} from 'react';
import {Space, Typography, Button, Drawer} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

export function RecommendAppsDrawer() {
  const [visible, setVisible] = useState(false);
  let href = null;

  var iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
  if (iOS) {
    href = 'https://apps.apple.com/ru/app/isbusiness/id1537612458';
  } else {
    href =
      'https://play.google.com/store/apps/details?id=info.xorcistexo.isbussiness&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
  }

  useEffect(() => {
    if (localStorage.getItem('fstMobileEnter')) {
      setVisible(true);
    }
  }, []);

  return (
    <Drawer
      className='recommend_apps'
      height='50%'
      placement='bottom'
      visible={visible}
      closable={false}
      title={
        <Space style={{justifyContent: 'space-between', display: 'flex'}}>
          <Space align='center'>
            <img
              style={{
                width: '50px',
                border: '1px solid #2e96fe',
                borderRadius: '8px',
              }}
              alt=''
              src='/static/img/system/isb_icon.png'
            />
            <Typography.Title level={3} style={{margin: 0}}>
              ISBUSINESS
            </Typography.Title>
          </Space>
          <CloseOutlined
            style={{fontSize: '1.5em'}}
            onClick={() => {
              setVisible(false);
            }}
          />
        </Space>
      }
      onClick={() => {
        localStorage.setItem('fstMobileEnter', true);
      }}
      onClose={() => setVisible(false)}>
      <Typography.Title level={4}>Скачайте наше приложение!</Typography.Title>
      <Button type='primary' href={href} style={{width: '100%'}}>
        Перейти
      </Button>
    </Drawer>
  );
}
