import React, {useState, useEffect} from 'react';
import {Typography, Space, Col, Row, Button, Carousel} from 'antd';
import {EventCard} from 'Base/Card';
import {EventBoxSkeleton} from './utils/components';
import {Link} from 'react-router-dom';
import {formatDate} from './utils/functions';
import {getProjectsAPI, getProjectsMobileAPI} from './API';
import '../style.css';
import './affiche.css';

export function Projects({postLoaded, fluid, title}) {
  const limit = 9;
  const title_affiche = title || 'Афиша мероприятий';
  const [events, setEvents] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [offset, setOffset] = useState(0);
  const [post_loaded, setPostLoaded] = useState(true);

  useEffect(
    (e) => {
      if (window.screen.width <= 425) {
        getProjectsMobileAPI({limit: limit, offset: offset}).then((res) => {
          setEvents((prev_state) => {
            res.data.map((el) => {
              el.date = formatDate({begin: el.date_time_start, end: el.date_time_end});
              el.description = '\nМесто: ' + el.location;
              el.balls = convert_balls(el.give_balls, el.cost_balls);
              return el;
            });
            return [...prev_state, ...res.data];
          });
          if (!res.data.length || res.data.length < 9) {
            setPostLoaded(false);
          }
          setLoaded(false);
        });
      } else {
        getProjectsAPI({
          limit: limit,
          offset: offset,
        }).then(async (res) => {
          setEvents((prev_state) => {
            res.data.map((el) => {
              el.date = formatDate({begin: el.date_time_start, end: el.date_time_end});
              el.description = '\nМесто: ' + el.location;
              el.balls = convert_balls(el.give_balls, el.cost_balls);
              return el;
            });
            return [...prev_state, ...res.data];
          });
          if (!res.data.length || res.data.length < 9) {
            setPostLoaded(false);
          }
          setLoaded(false);
        });
      }
    },
    [offset],
  );

  function convert_balls(give, cost) {
    return !+give ? +give - +cost : +give;
  }


  function renderEventBox() {
    if (window.screen.width <= 425) {
      return (
        <>
          {loaded ? (
            <>
              {[...Array(9)].map((_, i) => {
                return <EventBoxSkeleton key={i} />;
              })}{' '}
            </>
          ) : (
            <>
              {events.length ? (
                events.map((el) => {
                  return (
                    <Carousel
                      key={el.id}
                      infinite={false}
                      dotPosition='top'
                      style={{width: '100%'}}>
                      <Col md={8} sm={12} xs={24}>
                      <EventCard
                        title={el.name}
                        img={el.images}
                        url={`/project/${el.id}`}
                        description={<Typography.Paragraph>{el.date}</Typography.Paragraph>} />
                        </Col>
                      {el.events.map((child) => {
                        return (
                          <Col key={child.id} md={8} sm={12} xs={24}>
                          <EventCard
                            // start={child.date_time_start}
                            // end={child.date_time_end}
                            // score={{give_balls: child.give_balls, cost_balls: el.cost_balls}}
                            url={`/event/${child.id}`}
                            title={child.name}
                            img={child.images}
                            // location={child.location}
                          /></Col>
                        );
                      })}
                    </Carousel>
                  );
                })
              ) : (
                <Col xs={24}>
                  <Link to='/events/archive/'>
                    <img
                      style={{width: '100%'}}
                      alt='В данный момент мероприятий нет'
                      src='/static/img/system/archive_events.png'
                    />
                  </Link>
                </Col>
              )}
            </>
          )}
        </>
      );
    }
    return (
      <>
        {loaded ? (
          <>
            {[...Array(9)].map((_, i) => {
              return <EventBoxSkeleton key={i} />;
            })}
          </>
        ) : (
          <>
            {events.length ? (
              events.map((el) => {
                return (
                  <Col key={el.id} md={8} sm={12} xs={24}>
                    <EventCard
                      leftTopBall={el.balls}
                      title={el.name}
                      img={el.images}
                      url={`/project/${el.id}`}
                      description={<Typography.Paragraph>{el.date}</Typography.Paragraph>}
                    />
                  </Col>
                );
              })
            ) : (
              <Row justify='center' style={{margin: 0}}>
                <Col span={8}>
                  <Link to='/events/archive/'>
                    <img
                      style={{width: '100%'}}
                      alt='В данный момент мероприятий нет'
                      src='/static/img/system/archive_events.png'
                    />
                  </Link>
                </Col>
              </Row>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <section style={fluid ? {margin: '80px auto'} : {marginTop: '2em'}}>
      <Space className='title__link'>
        <Typography.Title level={2}>{title_affiche}</Typography.Title>
        <Link component={Typography.Link} to={'/projects/'}>
          ПОКАЗАТЬ ВСЕ
        </Link>
      </Space>
      {/* ТЕГИ. ВОЗМОЖНО БУДУТ В БУДУЩЕМ */}
      {/* <Typography.Title level={2}>{title_affiche}</Typography.Title> */}
      {/* <div className='affiche__tags'>
                <div className='affiche__tags-items'>
                    {tags_data.map((el) => {
                        return (<Tag.CheckableTag 
                                onChange={() => chooseTag(el)}
                                key={el.title} 
                                checked={selectedTag.title.indexOf(el.title) > -1} 
                                className="affiche__tag">{el.title}</Tag.CheckableTag>)
                    })}
                </div>
            </div> */}
      <Row style={{margin: '0 -10px'}} gutter={[20, 20]}>
        {renderEventBox()}
      </Row>
      {postLoaded && post_loaded ? (
        <Button
          size='large'
          style={{width: '100%'}}
          onClick={() => {
            setOffset(offset + 9);
            console.log(offset);
          }}>
          Загрузить еще
        </Button>
      ) : null}
    </section>
  );
}
