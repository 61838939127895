import React, {useState} from 'react';
import {Modal, Space, Typography, Divider, Button, Input, List, Form, notification} from 'antd';
import {getTariffPlanAPI, checkPromocodeAPI, createOrderAPI} from './API';
import {useEffectOnce} from 'react-use';
import {SuccessIcon} from 'Base/Icons/Success';
import {CloseIcon} from 'Base/Icons/Close';

function reformat(string) {
  return string.split('|');
}

export function CheckoutModal(props) {
  const [tariffList, setTariffList] = useState([]);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmData, setConfirmData] = useState(null);

  useEffectOnce(() => {
    getTariffPlanAPI(props.id).then((res) => {
      res.data.map((el) => {
        if (el.pros !== '') el.pros = reformat(el.pros);
        if (el.cons !== '') el.cons = reformat(el.cons);
        return el;
      });
      setTariffList(res.data);
    });
  }, []);

  return (
    <Modal {...props} footer={false} bodyStyle={{padding: '1em'}}>
      {tariffList.map((el, i) => {
        return (
          <Space
            key={i}
            direction='vertical'
            style={{background: '#F0F3F8', width: '100%', padding: '1em', margin: '0 0 2em 0'}}>
            <Typography.Title level={3}>{el.title}</Typography.Title>
            <Typography.Text>
              {el.price} <Typography.Text secondary>₽ / год</Typography.Text>
            </Typography.Text>
            <Divider style={{margin: 0}} />
            {Boolean(el.pros.length) && (
              <List
                dataSource={el.pros}
                renderItem={(text) => (
                  <div style={{display: 'flex'}}>
                    <SuccessIcon style={{fontSize: '2em'}} />
                    <Typography.Paragraph>{text}</Typography.Paragraph>
                  </div>
                )}
              />
            )}
            {Boolean(el.cons.length) && (
              <List
                dataSource={el.cons}
                renderItem={(text) => (
                  <div style={{display: 'flex'}}>
                    <CloseIcon style={{fontSize: '2em'}} />
                    <Typography.Paragraph>{text}</Typography.Paragraph>
                  </div>
                )}
              />
            )}
            <Button
              style={{width: '100%'}}
              onClick={() => {
                setConfirmData(el);
                setConfirmVisible(true);
              }}
              type='primary'>
              Оформить доступ
            </Button>
          </Space>
        );
      })}
      {confirmData && confirmVisible && (
        <ConfirmModal
          data={confirmData}
          visible={confirmVisible}
          title={confirmData.title}
          onChangeStatus={(status) => props.onChangeStatus(status)}
          onCancel={() => {
            setConfirmVisible(false);
          }}
        />
      )}
    </Modal>
  );
}

export function ConfirmModal(props) {
  const [promocodeVisible, setPromocodeVisible] = useState(false);
  const [checkPromocode, setCheckPromocode] = useState(null);
  let call = null;

  function confirm() {
    let data = {
      id_tariff: props.data.id,
      id_course: props.data.id_course,
    };
    if (checkPromocode && checkPromocode.status === 'success') {
      data.promocode = checkPromocode.promocode;
    }
    createOrderAPI(data).then((res) => {
      props.onChangeStatus(0);
      props.onCancel();
      notification[res.data.status]({
        message: res.data.message,
        description: 'Ваша заявка успешно принята. Ожидайте звонка менеджера для подтверждения.',
      });
    });
  }

  function checkProm(prom) {
    if (call) clearTimeout(call);

    function callAPI() {
      setCheckPromocode({status: 'validating'});
      checkPromocodeAPI({
        id_course: Number(props.data.id_course),
        promocode: prom,
      }).then((res) => {
        if (res.data.status === true) {
          setCheckPromocode({status: 'success', promocode: prom});
        } else {
          setCheckPromocode({status: 'error'});
        }
      });
    }

    call = setTimeout(callAPI, 1000);
  }

  return (
    <Modal {...props} footer={false} bodyStyle={{padding: '1em'}}>
      <Space direction='vertical' style={{width: '100%', padding: 0, margin: 0}}>
        <Typography.Title level={2}>
          {props.data.price} <Typography.Text secondary>₽ / год</Typography.Text>
        </Typography.Title>
        {Boolean(props.data.pros.length) && (
          <List
            dataSource={props.data.pros}
            renderItem={(text) => (
              <div style={{display: 'flex'}}>
                <SuccessIcon style={{fontSize: '2em'}} />
                <Typography.Paragraph>{text}</Typography.Paragraph>
              </div>
            )}
          />
        )}
        {Boolean(props.data.cons.length) && (
          <List
            dataSource={props.data.cons}
            renderItem={(text) => (
              <div style={{display: 'flex'}}>
                <CloseIcon style={{fontSize: '2em'}} />
                <Typography.Paragraph>{text}</Typography.Paragraph>
              </div>
            )}
          />
        )}
        <Typography.Text strong>
          При оформлении, с вами свяжется менеджер для уточнения покупки
        </Typography.Text>
        <Form>
          {promocodeVisible ? (
            <Form.Item
              style={{margin: '0 0 8px 0'}}
              hasFeedback
              validateStatus={checkPromocode && checkPromocode.status}>
              <Input onChange={(e) => checkProm(e.target.value)} />
            </Form.Item>
          ) : (
            <Form.Item style={{margin: '0 0 8px 0'}}>
              <Typography.Link onClick={() => setPromocodeVisible(true)}>
                Есть промокод?
              </Typography.Link>
            </Form.Item>
          )}
          <Button style={{width: '100%'}} onClick={confirm} type='primary' htmlType='submit'>
            Оформить
          </Button>
        </Form>
      </Space>
    </Modal>
  );
}
