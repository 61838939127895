import React, {Suspense} from 'react';
import 'antd/dist/antd.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {Loader} from 'Base/Loader/Loader';
import EntryPointClient from './Client';
import {LinksMobile} from 'Client/components/MobileAppsLink/LinksMobile';
import './index.css';

const AdminDashboard = React.lazy(() => import('Admin'));

function App() {
  return (
    <div className='wrapper'>
      <BrowserRouter>
        <Switch>
          <Route path='/admin/'>
            <Suspense fallback={<Loader />}>
              <AdminDashboard />
            </Suspense>
          </Route>
          <Route exact path='/apps/'>
            <LinksMobile />
          </Route>
          <Route path='/'>
            <EntryPointClient />
          </Route>
          <Route path='/apps/'>
            <LinksMobile />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
