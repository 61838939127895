import React, {useState} from 'react';
import {Typography, Row, Col, Divider, Input, Avatar, Comment, Button, Upload, Form, message} from 'antd';
import {useRouteMatch, Link} from 'react-router-dom';

import { CreateCommentAPI } from './API';
import {Loader} from 'Base/Loader/Loader';
import {UserOutlined} from '@ant-design/icons';
import {useUserContext} from 'Client/config/context';
import {useEffectOnce} from 'react-use';
import {getLessonOneAPI, getCommentsAPI} from './API';
import './course.css';

export function DetailLesson() {
  const [loadPage, setLoadPage] = useState(false);
  const [lesson, setLesson] = useState(null);
  const match = useRouteMatch();
  const [comments, setComments] = useState([]);
  const {user, setTheme} = useUserContext();

  function sendComment(data) {
    data.id_course = lesson?.id;

    console.log(data, user);

    CreateCommentAPI(data).then((res) => {
      message.success('комментарий отправлен');
      // setComments((prev) => {
      //   return [
      //     ...prev,
      //     {
      //       name: user.user.name,
      //       avatar: user.user.avatar,
      //       question: data.question,
      //     },
      //   ];
      // });
    });
  }

  useEffectOnce(() => {
    setTheme('secondary');
    setLoadPage(true);
    getLessonOneAPI(Number(match.params.id)).then((res) => {
      setLesson(res.data);
      setLoadPage(false);
    });
    getCommentsAPI(Number(match.params.id)).then(res => {
      setComments(res.data);
    })

    return () => {
      setTheme('primary');
    };
  });

  return (
    <div>
      {loadPage && <Loader />}
      <div
        className='affiche__detail_header color__font-white'
        style={{
          backgroundImage: `url(${
            lesson ? lesson.cover : null
          }), linear-gradient(to right, rgba(0,0,0, 0.8), rgba(0,0,0, 0.3))`,
          backgroundBlendMode: 'overlay',
        }}>
        <Typography.Title level={1} style={{margin: '0 0 10px 0'}}>
          {lesson && lesson.title}
        </Typography.Title>
        {lesson && (
          <Button type='primary' style={{marginTop: '1em'}}>
            <Link to={`/course/${lesson.id_course}`}>Перейти на курс</Link>
          </Button>
        )}
      </div>
      <div style={{margin: '50px auto'}}>
        <Row gutter={20} className='affiche__detail_container'>
          <Col xs={24} md={{span: 12, offset: 6}}>
            {lesson && (
              <div
                dangerouslySetInnerHTML={{__html: lesson.content}}
                style={
                  Number(lesson.status_buy) !== 1
                    ? {
                        whiteSpace: 'pre-wrap',
                        filter: `blur(${lesson.blur}px)`,
                        pointerEvents: 'none',
                      }
                    : {whiteSpace: 'pre-wrap'}
                }
              />
            )}
            <Form>
              <Form.Item>
                <Upload action={`/lesson/${lesson?.id}/upload.php`}>
                  <Button>Загрузка материала</Button>
                </Upload>
              </Form.Item>
            </Form>
            <Typography.Title style={{paddingTop: '1em'}} level={2}>
              Комментарии
            </Typography.Title>
            <Form onFinish={sendComment}>
              <Form.Item name='question'>
                <Input.TextArea rows={4} autoSize={false} placeholder='комментарий' />
              </Form.Item>
              <Button type='primary' htmlType='submit'>
                Отправить
              </Button>
            </Form>
            <Divider />
            {comments.map((el, i) => {
              return (
                <Comment
                  key={i}
                  author={<Typography.Text strong>{el.name}</Typography.Text>}
                  avatar={
                    <Avatar
                      src={el.avatar}
                      icon={<UserOutlined />}
                      style={{background: '#F0F3F8', color: '#2e96fe'}}
                    />
                  }
                  content={<Typography.Paragraph>{el.question}</Typography.Paragraph>}>
                  {el.answer !== '' ? (
                    <Comment
                      author='Администратор'
                      content={el.answer}
                      avatar={
                        <Avatar
                          icon={<UserOutlined />}
                          style={{background: '#F0F3F8', color: '#2e96fe'}}
                        />
                      }
                    />
                  ) : null}
                </Comment>
              );
            })}
          </Col>
        </Row>
      </div>
    </div>
  );
}
