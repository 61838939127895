import React, {useState, useEffect} from 'react';
import {Form, Modal, Result, Space, Button, Typography, Input, Alert} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {sendSMS} from './FormAPI';
import {useAuthContext} from 'Client/config/context';

export function ModalSucess() {
  const [visible, setVisible] = useState(true);

  return (
    <Modal visible={visible} onCancel={() => setVisible(false)} footer={false}>
      <Result
        status='success'
        title='Спасибо! Ваша анкета успешно отправлена!'
        subTitle='В ближайшее время наш специалист отправит вам всю подробную информацию о предпринимательстве'
        extra={[
          <Button key='ok' onClick={() => setVisible(false)} type='primary'>
            <Link to='/'>Перейти в личный кабинет</Link>
          </Button>,
        ]}
      />
    </Modal>
  );
}

export function ModalError({error, onClose}) {
  const [visible, setVisible] = useState(true);

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(false);
        onClose();
      }}
      footer={false}>
      <Result
        status='error'
        title='Упс!'
        subTitle={error}
        extra={[
          <Button
            key='ok'
            onClick={() => {
              setVisible(false);
              onClose();
            }}
            type='link'>
            <Link to={'/signin/'}>Перейти</Link>
          </Button>,
        ]}
      />
    </Modal>
  );
}

export function ModalConfidence({data, onClose}) {
  const [loading, setLoading] = useState(false);
  const [recallCode, setRecallCode] = useState(false);
  const [time, setTime] = useState(null);
  const [error, setError] = useState(null);
  const {Authorization} = useAuthContext();
  const [form] = Form.useForm();
  const history = useHistory();
  let title = `Подтвердите номер: +${data.phone_number}`;

  useEffect(() => {
    let id_time = null;
    if (time) {
      id_time = setInterval(() => {
        setTime(time - 1);
      }, 1000);
      return () => {
        clearInterval(id_time);
      };
    } else if (time === 0) {
      clearInterval(id_time);
      setRecallCode(false);
    }
  }, [time]);

  function handleOk(code) {
    setLoading(true);
    axios({
      url: 'https://inficomp.ru/anketa/api/confirmationbysms/checkcodesms.php',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${data.token}`,
      },
      data: JSON.stringify({
        phone_number: String(data.phone_number),
        code: String(code),
      }),
    }).then((res) => {
      if (res.data['status']) {
        setLoading(false);
        Authorization(data.token);
        let redirect = localStorage.getItem('utm');
        if (redirect) {
          localStorage.removeItem('utm');
          return history.push(redirect);
        }
        return history.push('/');
      } else {
        setLoading(false);
        form.resetFields();
        setError({text: res.data['message']});
      }
    });
  }
  function checkCode(i) {
    let code_1 = document.getElementById('code_1');
    let code_2 = document.getElementById('code_2');
    let code_3 = document.getElementById('code_3');
    let code_4 = document.getElementById('code_4');
    let code = code_1.value + code_2.value + code_3.value + code_4.value;
    if (++i !== 5) document.getElementById('code_' + i).focus();
    if (code.length === 4) {
      handleOk(code);
    }
  }

  function onRecallCode() {
    setRecallCode(true);
    sendSMS({phone: data.phone_number})
      .then((res) => {
        console.log('resend');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Modal
      onCancel={() => onClose(false)}
      visible={data.visible}
      title={title}
      footer={[
        <Button
          form='modal-confirm-code'
          key='submit'
          type='primary'
          htmlType='submit'
          loading={loading}>
          Отправить
        </Button>,
      ]}>
      <Form form={form} id='modal-confirm-code'>
        <Space direction='vertical' className='modal__confidence'>
          <Space>
            <Form.Item name='code_1' style={{marginBottom: '0'}}>
              <Input
                id='code_1'
                className='modal__confidence_button'
                maxLength={1}
                size='large'
                onChange={() => checkCode(1)}></Input>
            </Form.Item>
            <Form.Item name='code_2' style={{marginBottom: '0'}}>
              <Input
                id='code_2'
                className='modal__confidence_button'
                maxLength={1}
                size='large'
                onChange={() => checkCode(2)}></Input>
            </Form.Item>
            <Form.Item name='code_3' style={{marginBottom: '0'}}>
              <Input
                id='code_3'
                className='modal__confidence_button'
                maxLength={1}
                size='large'
                onChange={() => checkCode(3)}></Input>
            </Form.Item>
            <Form.Item name='code_4' style={{marginBottom: '0'}}>
              <Input
                id='code_4'
                className='modal__confidence_button'
                maxLength={1}
                size='large'
                onChange={() => checkCode(4)}></Input>
            </Form.Item>
          </Space>
          {recallCode ? (
            <Typography>Повторная отправка через {time} секунд</Typography>
          ) : (
            <Typography.Link
              onClick={() => {
                onRecallCode();
                setTime(120);
              }}>
              Не пришел код?
            </Typography.Link>
          )}
          {error ? <Alert message={error.text} type='error'></Alert> : null}
        </Space>
      </Form>
    </Modal>
  );
}
