import React from 'react';
import './loader.css';

export function Loader() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        zIndex: 100,
        right: 0,
        left: 0,
        background: 'rgb(247, 248, 250)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div className='loading'>
        <div className='loading__box'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='loading__box'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='loading__box'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='loading__box'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
