import axios from 'Client/config/axios';

export async function getCourseAPI(id) {
  return await axios.post('/course/read.php', JSON.stringify({id_course: id}));
}

export async function getLessonsAPI(id) {
  return await axios.post('/lesson/getlistlessons.php', JSON.stringify({id_course: id}));
}

export async function getLessonOneAPI(id) {
  return await axios.post('/lesson/read.php', JSON.stringify({id_lesson: id}));
}

export async function CreateCommentAPI(data) {
  return await axios.post('/user/createcoursequestion.php', JSON.stringify(data));
}

export async function getTariffPlanAPI(id) {
  return await axios.post('/course/getlistcourserates.php', JSON.stringify({id_course: id}));
}

export async function getCommentsAPI(id) {
  return await axios.post('/course/getcoursequestions.php', JSON.stringify({id_course: id}));
}

export async function createOrderAPI(data) {
  return await axios.post('/course/createapplication.php', JSON.stringify(data));
}

export async function checkPromocodeAPI(data) {
  return await axios.post('/course/checkpromocode.php', JSON.stringify(data));
}

export async function getPersonalCoursesAPI({limit, offset}){
  return await axios.post('/user/getmycourses.php', JSON.stringify({limit, offset}));
}