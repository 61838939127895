import React from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import {PrivateRoute} from './config/PrivateComponents/PrivateRouter';
import {
  Signin,
  Intro,
  ArchiveList,
  AfficheList,
  DetailAffiche,
  DetailCourse,
  DetailLesson,
  Projects,
  UserPrivilege,
  UserSetting,
  UserPreview,
  Shop,
  UserAfficheBoard,
  BaseLayout,
  PersonalCourseList,
  Chat,
  Panel
} from './components';
import {AuthContextProvider} from './config/context';
import {UserContextProvider} from './config/context';
//#1f232c

export default function EntryPointClient() {
  let {path} = useRouteMatch();
  return (
    <UserContextProvider>
      <AuthContextProvider>
        <BaseLayout>
          <Switch>
            <Route exact path='/signin/'>
              <Signin />
            </Route>
            <Route exact path='/register/'>
              <Intro />
            </Route>
            <PrivateRoute path='/'>
              <Switch>
                <Route exact path={`/events/archive/`}>
                  <Panel title='Архивные мероприятия'>
                    <ArchiveList postLoad={true} />
                  </Panel>
                </Route>
                <Route exact path={`/event/:id/`}>
                  <DetailAffiche rootUrl='event' />
                </Route>
                <Route exact path={`/events/`}>
                  <Panel title='Мои мероприятия'>
                    <AfficheList postLoad />
                  </Panel>
                </Route>
                <Route exact path={`/course/:id/`}>
                  <DetailCourse />
                </Route>
                <Route exact path={`/lesson/:id/`}>
                  <DetailLesson />
                </Route>
                <Route exact path={`/projects/`}>
                  <Projects fluid postLoaded />
                </Route>
                <Route exact path={`/project/:id/`}>
                  <DetailAffiche rootUrl='project' />
                </Route>
                <Route exact path={`/user/privilege/`}>
                  <UserPrivilege />
                </Route>
                <Route exact path={`/user/courses/`}>
                  <PersonalCourseList />
                </Route>
                <Route exact path={`/user/setting/`}>
                  <UserSetting />
                </Route>
                <Route exact path={`/user/`}>
                  <UserPreview />
                </Route>
                <Route path={`/shop/`}>
                  <Shop />
                </Route>
                <Route exact path={path}>
                  <UserAfficheBoard />
                </Route>
                <Route path='*' status={404}>
                  <div>404 not found page</div>
                </Route>
              </Switch>
              <Chat />
            </PrivateRoute>
          </Switch>
        </BaseLayout>
      </AuthContextProvider>
    </UserContextProvider>
  );
}
