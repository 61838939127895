import React from 'react';
import {Typography, Space} from 'antd';
import {Merch} from './Merch';
import {Course} from './Course';
import {Link, Switch, Route} from 'react-router-dom';

export function Shop() {
  return (
    <div>
      <Space size={20} style={{width: '100%', justifyContent: 'center'}}>
        <Link to='/shop/course/'>
          <Typography.Title type='secondary' level={4}>
            Курсы
          </Typography.Title>
        </Link>
        <Link to='/shop/merch/'>
          <Typography.Title type='secondary' level={4}>
            Мерч
          </Typography.Title>
        </Link>
      </Space>
      <Switch>
        <Route exact path='/shop/course/'>
          <Course visible />
        </Route>
        <Route exact path='/shop/merch/'>
          <Merch />
        </Route>
      </Switch>
    </div>
  );
}
