import React from 'react';
import {Layout} from 'antd';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

export default function BaseLayout(props) {
  return (
    <Layout
      className='account'
      style={{
        background: '#F7F8FA',
        minHeight: 'inherit',
        justifyContent: 'space-between',
      }}>
      <Header />
      <Layout.Content className='fluid-container-main'>{props.children}</Layout.Content>
      <Footer />
    </Layout>
  );
}
