export function filterDate(date) {
  return String(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
}

export function today() {
  let today = new Date();
  return filterDate(today);
}

export function yesterday() {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return filterDate(tomorrow);
}

export function nextWeek() {
  let next_week = new Date();
  next_week.setDate(next_week.getDate() + ((1 + 7 - next_week.getDay()) % 7));
  return filterDate(next_week);
}

export function formatPeriondicTime(begin, end) {
  if (begin && end) {
    let format_begin = String(begin.split(' ').pop().slice(0, -3));
    let format_end = String(end.split(' ').pop().slice(0, -3));
    if (format_begin === format_end) {
      return format_begin;
    }
    return format_begin + ' - ' + format_end;
  }
  return null;
}

export function formatDate({begin, end}) {
  let format_begin = '';
  let format_begin_time = '';
  let format_end = '';
  let format_end_time = '';

  if (begin) {
    format_begin_time = String(begin.split(' ').pop().slice(0, 5));
    format_begin = String(begin.split(' ').shift().split('-').reverse().join('.'));
  }
  if (end) {
    format_end_time = String(end.split(' ').pop().slice(0, 5));
    format_end = String(end.split(' ').shift().split('-').reverse().join('.'));
  }
  if (begin && end) {
    if (format_begin === format_end) {
      return format_begin + ' | ' + format_begin_time + ' - ' + format_end_time;
    }
    return format_begin + ' - ' + format_end;
  } else if (begin) {
    return format_begin;
  } else if (end) {
    return format_end;
  }
  return console.error('formatDate: date is undefined');
}
