import React, {useState, useEffect, useRef} from 'react';
import CustomVideo from './VideoFormat';
import ReactQuill, {Quill} from 'react-quill';
import {GalleryModal} from 'Base/Modal';
import config from 'config.json';

Quill.register('formats/video', CustomVideo);

let toolbarOptions = {
  container: [
    [{header: []}],
    [{size: []}], // custom dropdown

    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    ['image', 'video'],
    [{header: 1}, {header: 2}], // custom button values
    [{list: 'ordered'}, {list: 'bullet'}],
    [{script: 'sub'}, {script: 'super'}], // superscript/subscript
    [{indent: '-1'}, {indent: '+1'}], // outdent/indent
    [{direction: 'rtl'}], // text direction

    [{color: []}, {background: []}], // dropdown with defaults from theme
    [{align: []}],

    ['clean'], // remove formatting button
  ],
  handlers: {},
};

export default function ReactInputQuill({uploadCallback, listRequest, ...props}) {
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const ref = useRef();

  useEffect(() => {
    listRequest().then(res => {
      res.data.map((el) => {
        el.url = `${config.media}${el.url}`;
        return el;
      });
      setList(res.data);
    })
  }, [])

  function closeGallery(){
    let x = document.getElementById('set_image');
    x.remove();
    setVisible(false);

  }

  function selectedFileGallery(file){
    let x = document.getElementById('set_image');
    x.setAttribute('data', file.url);
    x.click();
    closeGallery();
  }

  toolbarOptions.handlers = {
    image: () => {
      const input = document.createElement('input');
      input.setAttribute('id', 'set_image');
      input.setAttribute('type', 'hidden');
      document.body.appendChild(input);
      setVisible(true);

      input.onclick = () => {
        let d = input.getAttribute('data');
        let quill = ref.current.getEditor();
        const range = quill.getSelection(true);
        quill.setSelection(range.index + 1);
        quill.deleteText(range.index, 1);
        quill.insertEmbed(range.index, 'image', `${d}`);
      };
    }
  }

  return (
    <>
      <ReactQuill ref={ref} modules={{toolbar: toolbarOptions}} {...props} />
      {visible && <GalleryModal 
        uploadRequest={uploadCallback} data={list} visible={visible}
        onSelect={selectedFileGallery}
        onCancel={closeGallery} />
      }
    </>
    )
}
