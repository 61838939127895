import React from 'react';
import {Col, Skeleton, Carousel} from 'antd';
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons';

export function EventBoxSkeleton({mode, span}) {
  let col = mode === 'min' ? span || 6 : span || 8;
  return (
    <Col md={col} sm={12} xs={24}>
      <Skeleton.Avatar className='autosize-container' active={true} shape='square' />
      <Skeleton paragraph={{rows: 2}} />
    </Col>
  );
}

export function ShopCarousel({...props}) {
  let settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: true,
    nextArrow: <Arrow styleCSS={{right: '25px'}} component={RightCircleOutlined} />,
    prevArrow: <Arrow styleCSS={{left: '2px'}} component={LeftCircleOutlined} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: true,
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: false,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          effect: 'scrollx',
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplay: false,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          effect: 'scrollx',
        },
      },
    ],
  };

  function Arrow({component: Component, ...props}) {
    const {className, style, onClick} = props;
    return (
      <Component
        className={className}
        style={{
          ...style,
          ...props.styleCSS,
          display: 'block',
          fontSize: '2em',
          color: 'rgba(46,150,254,.7)',
          zIndex: 50,
        }}
        onClick={onClick}
      />
    );
  }

  return <Carousel {...settings}>{props.children}</Carousel>;
}
