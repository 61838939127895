import React, {useState, useEffect} from 'react';
import {useEffectOnce} from 'react-use';
import {
  Typography,
  Row,
  Col,
  Form,
  Comment,
  Divider,
  Avatar,
  Input,
  Skeleton,
  List,
  Alert,
  Button,
} from 'antd';
import {useRouteMatch, Link} from 'react-router-dom';
import {Loader} from 'Base/Loader/Loader';
import {useUserContext} from 'Client/config/context';
import {getCourseAPI, getLessonsAPI, CreateCommentAPI, getCommentsAPI} from './API';
import {RightOutlined} from '@ant-design/icons';
import {CheckoutModal} from './CheckoutModal';
import {UserOutlined} from '@ant-design/icons';
import './course.css';

function EventBoxSkeleton() {
  return (
    <Skeleton.Avatar className='autosize-container' active={true} shape='square'></Skeleton.Avatar>
  );
}

export function DetailCourse(props) {
  const [onLoadVideo, setOnLoadVideo] = useState(false);
  const [loadPage, setLoadPage] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [tariffVisible, setTariffVisible] = useState(false);
  const [comments, setComments] = useState([]);
  const [event, setEvent] = useState(null);
  const [statusBuy, setStatusBuy] = useState(null);
  const match = useRouteMatch();
  const {user, setTheme} = useUserContext();

  const display = onLoadVideo ? 'block' : 'none';

  useEffectOnce(() => {
    setTheme('secondary');
    setLoadPage(true);
    Promise.all([
      getCourseAPI(Number(match.params.id)),
      getLessonsAPI(Number(match.params.id)),
      getCommentsAPI(Number(match.params.id)),
    ]).then((res) => {
      setEvent(res[0].data.info);
      setStatusBuy(res[0].data.status_buy);
      setLessons(res[1].data);
      setComments(res[2].data);
      setLoadPage(false);
    });

    return () => {
      setTheme('primary');
    };
  });

  function sendComment(data) {
    data.id_course = event.id;
    CreateCommentAPI(data).then((res) => {
      setComments((prev) => {
        return [
          ...prev,
          {
            name: user.user.name,
            avatar: user.user.avatar,
            question: data.question,
          },
        ];
      });
    });
  }

  function statusOrder(status) {
    if (status) {
      setStatusBuy(status);
    }
  }

  return (
    <div>
      {loadPage && <Loader />}
      <div
        className='affiche__detail_header color__font-white'
        style={{
          backgroundImage: `url(${
            event ? event.cover : null
          }), linear-gradient(to right, rgba(0,0,0, 0.8), rgba(0,0,0, 0.3))`,
          backgroundBlendMode: 'overlay',
        }}>
        <Typography.Title level={1} style={{margin: '0 0 10px 0'}}>
          {event && event.name}
        </Typography.Title>
        {statusBuy && statusBuy === -1 ? (
          <Button type='primary' onClick={() => setTariffVisible(true)} style={{marginTop: '1em'}}>
            Оформить
          </Button>
        ) : statusBuy === 0 ? (
          <Button style={{marginTop: '1em'}}>На оформлении</Button>
        ) : statusBuy === 1 ? (
          <Alert type='success' message='Курс приобретён' style={{display: 'inline-flex'}} />
        ) : (
          <Button type='primary' onClick={() => setTariffVisible(true)} style={{marginTop: '1em'}}>
            Оформить
          </Button>
        )}
      </div>
      <div style={{margin: '50px auto'}}>
        <Row gutter={20} className='affiche__detail_container'>
          <Col xs={24} md={12}>
            <Typography.Title level={2}>Описание</Typography.Title>
            {event ? (
              <div
                dangerouslySetInnerHTML={{__html: event.content}}
                style={{whiteSpace: 'pre-wrap'}}
              />
            ) : null}
            <Typography.Title level={2}>Уроки</Typography.Title>
            {lessons.length && (
              <List
                itemLayout='horizontal'
                dataSource={lessons}
                renderItem={(item) => (
                  <Link to={`/lesson/${item.id}`}>
                    <List.Item className='course__container' style={{padding: '8px 1em'}}>
                      <List.Item.Meta title={item.title} description={item.title} />
                      <RightOutlined />
                    </List.Item>
                  </Link>
                )}
              />
            )}
            <Typography.Title style={{paddingTop: '1em'}} level={2}>
              Вопрос / Ответ
            </Typography.Title>
            <Form onFinish={sendComment}>
              <Form.Item name='question'>
                <Input.TextArea rows={4} autoSize={false} placeholder='Введите ваш вопрос...' />
              </Form.Item>
              <Button type='primary' htmlType='submit'>
                Отправить
              </Button>
            </Form>
            <Divider />
            {comments.map((el, i) => {
              return (
                <Comment
                  key={i}
                  author={<Typography.Text strong>{el.name}</Typography.Text>}
                  avatar={
                    <Avatar
                      src={el.avatar}
                      icon={<UserOutlined />}
                      style={{background: '#F0F3F8', color: '#2e96fe'}}
                    />
                  }
                  content={<Typography.Paragraph>{el.question}</Typography.Paragraph>}>
                  {el.answer !== '' ? (
                    <Comment
                      author='Администратор'
                      content={el.answer}
                      avatar={
                        <Avatar
                          icon={<UserOutlined />}
                          style={{background: '#F0F3F8', color: '#2e96fe'}}
                        />
                      }
                    />
                  ) : null}
                </Comment>
              );
            })}
          </Col>

          <Col xs={24} md={12} style={{width: '100%'}}>
            <Typography.Title level={2}>Видео</Typography.Title>
            <div style={{display: display}} className='autosize-container'>
              <iframe
                onLoad={() => setOnLoadVideo(true)}
                src={event ? event.video : null}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                title='Demo video'></iframe>
            </div>
            {!onLoadVideo ? <EventBoxSkeleton /> : null}
          </Col>
        </Row>
        {event && event.id && (
          <CheckoutModal
            id={event.id}
            visible={tariffVisible}
            onChangeStatus={statusOrder}
            onCancel={() => {
              setTariffVisible(false);
            }}
            title='Выберите тарифный план'
          />
        )}
      </div>
    </div>
  );
}
