import React, {useEffect, useState} from 'react';
import {Modal, Upload, Input, Button, Image, Space, Tabs, message} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import config from 'config.json';
import axios from 'axios';

export default function GalleryModal({onSelect, uploadRequest, data, ...props}) {

  function onSelectFile(file) {
    if (typeof onSelect === 'function') {
      onSelect(file);
    }
  }

  return (
    <Modal {...props} width='85%' title='Галерея изображений' footer={null}>
      <Tabs defaultActiveKey='1'>
        <Tabs.TabPane tab='Загрузка' key='1'>
          <div>
            <UploadFile onSelectFile={onSelectFile} uploadRequest={uploadRequest} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Внешняя ссылка' key='2'>
          <UploadExternal onSelectFile={onSelectFile} uploadRequest={uploadRequest} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Библиотека изображений' key='3'>
          <GalleryList data={data} onSelectFile={onSelectFile} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}

function UploadExternal({onSelectFile, uploadRequest}) {
  const [file, setFile] = useState(null);
  const [externalImageURL, setExtUrl] = useState(null);

  function onSave() {
    if (typeof onSelectFile === 'function') {
      uploadFile(file).then((res) => {
        onSelectFile(res.data);
      });
    }
  }

  async function uploadFile(file) {
    try {
      await uploadRequest(file);
      message.success('Картинка успешно загружена');
    } catch (err) {
      console.log('Возникла ошибка при отправке');
    }
  }

  return (
    <>
      <Input.Search
        onSearch={(s) => {
          setExtUrl(s);
          axios({
            url: s, method: 'GET', responseType: 'blob', // Important
          })
            .then((res) => {
              let file = new File([res.data], 'filename', {
                type: res.data.type,
              });
              setFile(file);
            })
            .catch(() => {
              message.error('Некорректная ссылка');
            });
        }}
      />
      {externalImageURL && (
        <Space direction='vertical' style={{marginTop: '1em'}}>
          <Image width={300} src={externalImageURL} />
          <Button onClick={onSave} style={{alignSelf: 'flex-end'}}>
            Сохранить
          </Button>
        </Space>
      )}
    </>
  );
}

function UploadFile({onChange, uploadRequest, onSelectFile}) {
  const [fileList, setFileList] = useState([]);
  const [file, setCurrectFile] = useState(null);

  function upload(e) {
    setFileList(e.fileList);
    onChange(e.file);
  }

  function onSelectClick() {
    if (typeof onSelectFile === 'function' && file) {
      let f = file;
      f.url = config.media + file.url;
      onSelectFile(f);
      setFileList([]);
    }
  }

  return (
    <>
      <Upload.Dragger
        maxCount={1}
        listType='picture'
        fileList={fileList}
        onChange={upload}
        beforeUpload={false}
        customRequest={() => {
          return null;
        }}
        action={(file) => {
            uploadRequest(file).then((res) => {
              setFileList((prev) => {
                let newFileList = [...prev];
                newFileList.map((file) => {
                  if (file.name === res.data.name) {
                    file.status = res.data.status;
                    if (res.data.status === 'done') {
                      setCurrectFile(res.data);
                    }
                  }
                  return file;
                });
                return newFileList;
              });
            })
            .catch((err) => {
              return Promise.reject('error');
            });
        }}>
        <div>
          <PlusOutlined />
          <div style={{marginTop: 8}}>Загрузка</div>
        </div>
      </Upload.Dragger>
      {fileList.length > 0 && (
        <Button onClick={onSelectClick} style={{marginTop: '1em'}}>
          Сохранить
        </Button>
      )}
    </>
  );
}

function GalleryList({data, onSelectFile}) {
  function onSelect(file) {
    if (typeof onSelectFile === 'function') {
      onSelectFile(file);
    }
  }

  return (
    <Space wrap>
      {data.map((el) => {
        return (
          <Image
            key={el.url}
            style={{cursor: 'pointer'}}
            onClick={() => onSelect(el)}
            preview={false}
            width={200}
            src={el.url}
          />
        );
      })}
    </Space>
  );
}

export function InputGallery({value, onDelete, onChoose}) {
  const [url, setUrl] = useState(value);

  useEffect(() => {
    setUrl(value);
  }, [value]);

  function onClickDelete() {
    if (typeof onDelete === 'function') {
      onDelete();
      setUrl(null);
    }
  }

  function onChooseClick() {
    if (typeof onChoose === 'function') {
      onChoose();
    }
  }

  return (
    <Space direction='vertical' style={{marginBottom: '1em'}}>
      {url && <Image src={url} />}
      <Space>
        <Button onClick={onChooseClick}>Выбрать изображение</Button>
        {url && <Button onClick={onClickDelete}>Удалить</Button>}
      </Space>
    </Space>
  );
}
