import React, {useState} from 'react';
import {Form, Row, Col, Button, Checkbox, Typography, Radio, Input, DatePicker} from 'antd';
import {CityInputSelect} from 'Base/Inputs';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

const margin = {bottom: {marginBottom: '8px'}};

function FormRadioItem({title, name, children}) {
  return (
    <>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Form.Item name={name} rules={[{required: true, message: 'Не выбрана категория'}]}>
        <Radio.Group>{children}</Radio.Group>
      </Form.Item>
    </>
  );
}

export function SecondVariant({questions, loading}) {
  const [checkAggree, setCheckAggree] = useState(true);
  let form_field = questions.second_form;
  return (
    <>
      <FormRadioItem name={'type_person'} title={form_field.type_person.title}>
        {form_field.type_person.fields.map((el) => {
          return (
            <Row key={el.value} style={margin.bottom}>
              <Radio value={el.value}>{el.title}</Radio>
            </Row>
          );
        })}
      </FormRadioItem>

      <Row gutter={16}>
        <Col xs={{span: 24}} sm={{span: 8}}>
          <Typography.Title level={4}>{questions.surname.title}</Typography.Title>
          <Form.Item
            name='surname'
            rules={[
              {required: true, message: 'Фамилия не заполнена'},
              {
                pattern: /^([А-ЯЁа-яё]*)$/iu,
                message: 'Допустимы символы только в русском алфавите',
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 8}}>
          <Typography.Title level={4}>{questions.name.title}</Typography.Title>
          <Form.Item
            name='name'
            rules={[
              {required: true, message: 'Имя не заполнено'},
              {
                pattern: /^([А-ЯЁа-яё]*)$/iu,
                message: 'Допустимы символы только в русском алфавите',
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 8}}>
          <Typography.Title level={4}>{questions.last_name.title}</Typography.Title>
          <Form.Item
            name='last_name'
            rules={[
              {required: true, message: 'Отчество не заполнено'},
              {
                pattern: /^([А-ЯЁа-яё]*)$/iu,
                message: 'Допустимы символы только в русском алфавите',
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Typography.Title level={4}>{questions.birthday.title}</Typography.Title>
      <Form.Item name='birthday' rules={[{required: true, message: 'Дата рождения не указана'}]}>
        <DatePicker
          locale={locale}
          placeholder='дд-мм-гггг'
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DD.MM.YYYY']}
          style={{width: '100%'}}
        />
      </Form.Item>

      <Typography.Title level={4}>{questions.phone_number.title}</Typography.Title>
      <Form.Item
        name='phone_number'
        validateTrigger='onBlur'
        rules={[
          {required: true, message: 'Телефон не указан'},
          {pattern: /^[0-9]{10}$/, message: 'Неккоректно указан телефон'},
        ]}>
        <Input addonBefore={'+7'} />
      </Form.Item>

      <Typography.Title level={4}>{questions.city.title}</Typography.Title>
      <Form.Item
        name='city'
        validateTrigger='onBlur'
        rules={[{required: true, message: 'Город не указан'}]}>
        <CityInputSelect />
      </Form.Item>

      <Typography.Title level={4}>{questions.email.title}</Typography.Title>
      <Form.Item
        name='email'
        validateTrigger='onBlur'
        rules={[
          {required: true, message: 'Email не указан'},
          {
            pattern: /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: 'Неккоректно указан email',
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Row>
          <Col sm={{span: 8}} xs={{span: 24}}>
            <Button
              disabled={checkAggree}
              loading={loading}
              style={{width: '100%', marginBottom: '1em'}}
              size={'large'}
              type='primary'
              htmlType='submit'>
              Отправить ответы
            </Button>
          </Col>
          <Col sm={{span: 16}} xs={{span: 24}} style={{paddingLeft: '1em'}}>
            <Form.Item name='aggreement'>
              <Checkbox
                onChange={(e) => {
                  setCheckAggree(!e.target.checked);
                }}
                checked={!checkAggree}>
                <Typography.Paragraph style={{display: 'inline', color: 'black'}}>
                  Нажимая кнопку «Отправить ответы» вы даете согласие на обработку &nbsp;
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://isbusiness.ru/agreeperconaldata.pdf'>
                    персональных данных
                  </a>
                </Typography.Paragraph>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
}
