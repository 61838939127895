import React, {useState, useEffect} from 'react';
import {
  Form,
  Typography,
  Space,
  Radio,
  Checkbox,
  Upload,
  Button,
  message,
  Select,
  Avatar,
  Row,
  Col,
  Input,
} from 'antd';
import questions from '../Form/question.map.json';
import {infoUserAPI, infoUserInterestsAPI, resaveUserAPI, uploadPhotoUserAPI} from './UserAPI';
import {UserOutlined} from '@ant-design/icons';
import {useUserContext} from 'Client/config/context';
import {Loader} from 'Base/Loader/Loader';

const margin = {bottom: {marginBottom: '8px'}};

export function UserSetting() {
  let form_field = questions.first_form;

  const [userInfo, setUserInfo] = useState(null);
  const [interests, setInterests] = useState([]);
  const [someInterests, setSomeInterests] = useState([]);
  const [pageLoad, setPageLoad] = useState(true);
  const {user, setUserSettings} = useUserContext();

  useEffect(() => {
    setPageLoad(true);
    Promise.all([infoUserAPI(), infoUserInterestsAPI()]).then((res) => {
      let user = res[0].data;
      let user_interests = res[1].data;

      let mainInterests = [];
      let someInterests = [];
      user_interests.map((el) => {
        if (Number(el.id_interest) <= 15) {
          return mainInterests.push(Number(el.id_interest));
        }
        return someInterests.push(Number(el.id_interest));
      });
      setInterests(mainInterests);
      setSomeInterests(someInterests);
      setUserInfo(user);
      setPageLoad(false);
    });
  }, []);

  function resaveUser(data) {
    resaveUserAPI(data)
      .then((res) => {
        if (res.data.status) {
          setUserSettings((prev_state) => {
            return {
              ...prev_state,
              score: user.score,
              name: data.name,
              surname: data.surname,
              last_name: data.last_name,
            };
          });
          message.success('Настройки сохранены');
        }
      })
      .catch(() => {
        message.error('Произошла ошибка');
      });
  }

  return (
    <div style={{margin: '50px auto'}}>
      {pageLoad && <Loader />}
      <Space style={{width: '100%'}} direction='vertical' align='center'>
        <Avatar
          size={100}
          src={user && user.avatar}
          icon={<UserOutlined />}
          style={{background: '#F0F3F8', color: '#2e96fe'}}
        />
        <Upload
          showUploadList={false}
          action={(e) =>
            uploadPhotoUserAPI({photo: e}).then((res) => {
              //auth.setUser((prev_state) => {return {...prev_state, avatar: null}})
              message.success('Аватар обновлён');
            })
          }
          accept='.jpg,.png'>
          <Typography.Link>Сменить фото</Typography.Link>
        </Upload>
        <Typography.Title level={4} style={{margin: '0'}}>
          {user && user.surname}
        </Typography.Title>
        <Typography.Title level={4} style={{margin: '0'}}>
          {user && (
            <>
              {user.name} {user.last_name}{' '}
            </>
          )}
        </Typography.Title>
      </Space>
      {userInfo && interests && someInterests ? (
        <Form
          style={{marginTop: '50px'}}
          onFinish={(e) => resaveUser(e)}
          initialValues={{
            learning_topics: someInterests,
            name: userInfo.name,
            surname: userInfo.surname,
            last_name: userInfo.last_name,
            email: userInfo.email,
            phone_number: userInfo.phone,
            interests: interests,
          }}>
          <Row gutter={20}>
            <Col xs={{span: 24}} lg={{span: 8, offset: 4}}>
              <Typography.Paragraph style={{margin: '0'}}>Фамилия</Typography.Paragraph>
              <Form.Item name='surname'>
                <Input />
              </Form.Item>
              <Typography.Paragraph style={{margin: '0'}}>Имя</Typography.Paragraph>
              <Form.Item name='name'>
                <Input />
              </Form.Item>
              <Typography.Paragraph style={{margin: '0'}}>Отчество</Typography.Paragraph>
              <Form.Item name='last_name'>
                <Input />
              </Form.Item>
              <Typography.Paragraph style={{margin: '0'}}>Почта</Typography.Paragraph>
              <Form.Item name='email'>
                <Input disabled />
              </Form.Item>
              <Typography.Paragraph style={{margin: '0'}}>Телефон</Typography.Paragraph>
              <Form.Item name='phone_number'>
                <Input disabled />
              </Form.Item>
              <Form.Item>
                Вы предприниматель?
                <br />
                <Radio.Group
                  disabled
                  value={userInfo ? (Number(userInfo.id_type) === 1 ? 1 : 0) : null}>
                  <Radio.Button className='form_corners_left' value={1}>
                    Да
                  </Radio.Button>
                  <Radio.Button className='form_corners_right' value={0}>
                    Нет
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                Cогласие на обработку персональных данных
                <br />
                <Checkbox disabled checked />
              </Form.Item>
              <p>Для изменения данных пунктов вам необходимо обратиться в службу поддержки</p>
            </Col>
            <Col xs={{span: 24}} lg={{span: 8}}>
              <Typography.Paragraph style={{margin: '0'}}>Область интересов</Typography.Paragraph>
              <Form.Item
                name='interests'
                rules={[{required: true, message: 'Выберите хотя бы одно направление'}]}>
                <Select mode='multiple'>
                  {questions.interests.fields.map((el) => {
                    return (
                      <Select.Option key={el.value} value={el.value}>
                        {el.title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              {userInfo ? (
                Number(userInfo.id_type) === 1 ? (
                  <Form.Item
                    name='learning_topics'
                    rules={[{required: true, message: 'Выберите интересующие темы'}]}>
                    <Checkbox.Group>
                      {form_field.learning_topics.fields.map((el) => {
                        return (
                          <Row key={el.value} style={margin.bottom}>
                            <Checkbox value={el.value}>{el.title}</Checkbox>
                          </Row>
                        );
                      })}
                    </Checkbox.Group>
                  </Form.Item>
                ) : null
              ) : null}

              <Button style={{marginBottom: '1em'}} size={'large'} type='primary' htmlType='submit'>
                Сохранить
              </Button>
            </Col>
          </Row>
        </Form>
      ) : null}
    </div>
  );
}
