import React, {useState, useEffect} from 'react';
import {Typography, Select, Skeleton} from 'antd';
import axios from 'axios';

export default function CityInputSelect(props) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  let timeout = null;

  useEffect(() => {
    if (props.defaultValue || props.value) {
      setLoading(true);
      let value = props.defaultValue || props.value;
      console.log(String(value));
      search(String(value));
    }
  }, []);

  function onChange(value) {
    if (props.onChange === 'function') {
      props.onChange(value);
    }
  }

  function search(value) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    onChange(value);

    function API() {
      axios('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Token 09149ae33eb6cc21c624536e65b47d64885e07ab',
        },
        data: JSON.stringify({
          query: value,
          from_bound: { value: "city" },
          to_bound: { value: "city" }
        }),
      }).then((res) => {
        setList(res.data.suggestions);
      });
    }
    timeout = setTimeout(API, 300);
    setLoading(false);
  }

  return (
    <>
      {!loading ? (
        <Select
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={search}
          notFoundContent={null}
          {...props}>
          {list &&
            list.map((el) => {
              return (
                <Select.Option key={el.data.city} value={`${el.data.city}:${el.data.region_kladr_id}`}>
                  <Typography.Text>{el.data.city}</Typography.Text>
                </Select.Option>
              );
            })}
        </Select>
      ) : (
        <Skeleton.Input size='default' style={{width: '100%'}} active />
      )}
    </>
  );
}
