import React, {useState, useEffect} from 'react';
import {Col, Row, Typography, Button, Tag, Select} from 'antd';
import {EventCard} from 'Base/Card';
import {useList} from 'react-use';
import {getArchiveEventAPI} from './API';
import {formatDate} from './utils/functions';
import {DownOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';

ArchiveList.propTypes = {
  postLoad: PropTypes.bool,
};

export function ArchiveList(props) {
  const limit = 9;
  const [list, {push, set}] = useList([]);
  const [offset, setOffset] = useState(0);
  const [postLoad, setPostLoad] = useState(true);
  const [filter, setFilter] = useState({date_sort: 'DESC', interests: []});

  useEffect(() => {
    set([]);
    getArchiveEventAPI({limit: limit, offset: offset, ...filter}).then((res) => {
      if (res.data.length < limit) setPostLoad(false);
      res.data.map((el) => {
        el.date = formatDate({begin: el.date_time_start, end: el.date_time_end});
        el.description = '\nМесто: ' + el.location;
        el.balls = convert_balls(el.give_balls, el.cost_balls);
        return el;
      });
      set(res.data);
    });
  }, [filter]);

  useEffect(() => {
    getArchiveEventAPI({limit: limit, offset: offset, ...filter}).then((res) => {
      if (res.data.length < limit) setPostLoad(false);
      res.data.map((el) => {
        el.date = formatDate({begin: el.date_time_start, end: el.date_time_end});
        el.description = '\nМесто: ' + el.location;
        el.balls = convert_balls(el.give_balls, el.cost_balls);
        return el;
      });
      push(...res.data);
    });
  }, [offset]);

  function toggleSort() {
    if (filter.date_sort === 'ASC') {
      setFilter((prev) => {
        return {...prev, date_sort: 'DESC'};
      });
    } else {
      setFilter((prev) => {
        return {...prev, date_sort: 'ASC'};
      });
    }
  }

  function convert_balls(give, cost) {
    return !+give ? +give - +cost : +give;
  }

  return (
    <>
      <div className='affiche__tags'>
        <div className='affiche__tags-items'>
          <Tag
            icon={<DownOutlined style={{fontSize: '1em'}} />}
            className='affiche__tag'
            onClick={toggleSort}>
            Сортировка
          </Tag>
          <Select
            mode='multiple'
            tagRender={function tagRender(props) {
              const {label, closable, onClose} = props;
              return (
                <Tag className='affiche__tag' closable={closable} onClose={onClose}>
                  {label}
                </Tag>
              );
            }}
            onChange={(e) => {
              setFilter((prev) => {
                return {...prev, interests: e};
              });
              setOffset(0);
            }}
            maxTagCount='responsive'
            style={{width: '400px', background: 'rgb(247, 248, 250)'}}>
            <Select.Option key='1' value={1}>
              Производство
            </Select.Option>
            <Select.Option key='2' value={2}>
              Продажи
            </Select.Option>
            <Select.Option key='3' value={3}>
              Экспорт/Импорт
            </Select.Option>
            <Select.Option key='4' value={4}>
              Услуги
            </Select.Option>
            <Select.Option key='5' value={5}>
              IT
            </Select.Option>
            <Select.Option key='6' value={6}>
              Фермерство
            </Select.Option>
            <Select.Option key='7' value={7}>
              Сельское хозяйство
            </Select.Option>
            <Select.Option key='8' value={8}>
              Женский бизнес
            </Select.Option>
            <Select.Option key='9' value={16}>
              Аудит и построение бизнес-процессов
            </Select.Option>
            <Select.Option key='10' value={17}>
              Переговоры и продажи
            </Select.Option>
            <Select.Option key='11' value={18}>
              Маркетинг
            </Select.Option>
            <Select.Option key='12' value={19}>
              Командообразование и развитие персонала
            </Select.Option>
            <Select.Option key='13' value={20}>
              Бухгалтерия и финансовый учёт
            </Select.Option>
            <Select.Option key='14' value={21}>
              Управленческий учёт
            </Select.Option>
            <Select.Option key='15' value={22}>
              Личная эффективность
            </Select.Option>
            <Select.Option key='16' value={23}>
              Франчайзинг
            </Select.Option>
            <Select.Option key='17' value={24}>
              Продажи на маркетплейсах
            </Select.Option>
          </Select>
        </div>
      </div>
      <Row gutter={[20, 20]}>
        {list.map((el) => {
          return (
            <Col key={el.id} md={8} sm={12} xs={24}>
              <EventCard
                leftTopBall={el.balls}
                title={el.name}
                img={el.images}
                url={`/event/${el.id}`}
                description={<Typography.Paragraph>{el.date}</Typography.Paragraph>}
              />
            </Col>
          );
        })}
      </Row>
      {postLoad && props.postLoad && (
        <Button size='large' style={{width: '100%'}} onClick={() => setOffset(offset + 9)}>
          Загрузить еще
        </Button>
      )}
    </>
  );
}
