import React, {useState, useEffect} from 'react';
import {Tag, Input, Row, Col} from 'antd';
import {getCourseListAPI, getCoursePromocodeAPI} from './ShopAPI';
import {EventCard} from 'Base/Card';

export function Course(props) {
  const [courseList, setCourseList] = useState([]);
  const [interests, setInterests] = useState([]);
  const [visibleTags, setVisibleTags] = useState('none');
  const [promocodeVisible, setPromocodeVisible] = useState('none');

  let tags_data = [
    {title: 'Производство', id: 1},
    {title: 'Продажи', id: 2},
    {title: 'Экспорт/Импорт', id: 3},
    {title: 'Услуги', id: 4},
    {title: 'IT-технологии', id: 5},
    {title: 'Фермерство', id: 6},
    {title: 'Сельское хозяйство', id: 7},
    {title: 'Женский бизнес', id: 8},
    {title: 'Аудит и постороение бизнес-процессов', id: 16},
    {title: 'Переговоры и продажи', id: 17},
    {title: 'Маркетинг', id: 18},
    {title: 'Командообразование и развитие персонала', id: 19},
    {title: 'Бухгалтерия и финансовый учёт', id: 20},
    {title: 'Управленческий учёт', id: 21},
    {title: 'Личная эффективность', id: 22},
    {title: 'Франчайзинг', id: 23},
    {title: 'Продажи на маркетплейсах', id: 24},
  ];

  function toggleVisiblePromocode() {
    if (promocodeVisible === 'none') setPromocodeVisible('inline-block');
    if (promocodeVisible === 'inline-block') setPromocodeVisible('none');
  }

  function onSearch(value) {
    getCoursePromocodeAPI({promocode: value}).then((res) => {
      setCourseList(res.data);
    });
  }

  function toggleVisibleTags() {
    if (visibleTags === 'none') setVisibleTags('inline-block');
    if (visibleTags === 'inline-block') setVisibleTags('none');
  }

  function selectTags(tag, checked) {
    const selected = checked ? [...interests, tag.id] : interests.filter((f) => f !== tag.id);
    setInterests(selected);
  }

  useEffect(() => {
    getCourseListAPI({interests: interests, free_tariff: -1}).then((res) => {
      setCourseList(res.data);
    });
  }, [interests]);

  return (
    <section>
      {/* <Button icon={<UpOutlined />} size='small' style={{background: '#E7EBF3', border: 0}}>Сортировка</Button> */}
      {props.visible && (
        <>
          <div>
            <Tag.CheckableTag style={{margin: '0 5px 10px'}} className='affiche__tag'>
              Сортировка
            </Tag.CheckableTag>
            <Tag.CheckableTag
              style={{margin: '0 5px 10px'}}
              onClick={toggleVisibleTags}
              className='affiche__tag'>
              Интересы
            </Tag.CheckableTag>
            <Tag.CheckableTag
              style={{margin: '0 5px 10px'}}
              onClick={toggleVisiblePromocode}
              className='affiche__tag'>
              Пойск по промокоду
            </Tag.CheckableTag>
          </div>
          {tags_data.map((el) => {
            return (
              <Tag.CheckableTag
                style={{margin: '0 5px 10px', display: visibleTags}}
                onChange={(checked) => selectTags(el, checked)}
                key={el.id}
                checked={interests.indexOf(el.id) > -1}
                className='affiche__tag'>
                {el.title}
              </Tag.CheckableTag>
            );
          })}
          <Input.Search
            placeholder='пойск по промокоду'
            onSearch={onSearch}
            style={{
              width: '200px',
              height: '30px',
              margin: '0 10px 10px 0',
              display: promocodeVisible,
            }}
          />
        </>
      )}

      <Row style={{margin: '0 -10px'}} gutter={[20, 20]}>
        {courseList.map((evt) => {
          return (
            <Col key={evt.id} md={8} sm={12} xs={24}>
              <EventCard url={`/course/${evt.id}/`} title={evt.name} img={evt.image} />
            </Col>
          );
        })}
      </Row>
    </section>
  );
}
