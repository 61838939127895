import axios from 'Client/config/axios';

export async function getAPIevents({limit, offset, filter}) {
  let data = {limit: Number(limit), offset: Number(offset)};
  if (filter) {
    data['filter_date'] = filter;
  }
  return await axios.post('/project/getprojects.php', JSON.stringify(data));
}

export async function getProjectsAPI({limit, offset, filter}) {
  let data = {limit: Number(limit), offset: Number(offset)};
  if (filter) {
    data['filter_date'] = filter;
  }
  return await axios.post('/project/getprojects.php', JSON.stringify(data));
}

export async function sendToCHATGPT(data) {
  return await axios.post('/user/sendbusinessplan.php', JSON.stringify(data));
}

export async function getProjectsMobileAPI({limit, offset, filter}) {
  let data = {limit: Number(limit), offset: Number(offset)};
  if (filter) {
    data['filter_date'] = filter;
  }
  return await axios.post('/project/getprojectsfordashboard.php', JSON.stringify(data));
}

export async function getUserEventsAPI({limit, offset}) {
  let data = {limit: Number(limit), offset: Number(offset)};
  return await axios.post('/user/getmyevents.php', JSON.stringify(data));
}

export async function getDetailProjectAPI({event}) {
  let data = {id_project: Number(event)};
  return await axios.post(`/project/getinfo.php`, JSON.stringify(data));
}

export async function getDetailEventAPI({event}) {
  let data = {id_event: Number(event)};
  return await axios.post(`/event/getinfo.php`, JSON.stringify(data));
}

export async function getIsRegisterProjectAPI({event}) {
  let data = {id_project: Number(event)};
  return await axios.post('/project/checkregistration.php', JSON.stringify(data));
}

export async function getIsRegisterEventAPI({event}) {
  let data = {id_event: Number(event)};
  return await axios.post('/event/checkuserregistration.php', JSON.stringify(data));
}

export async function subscribeProjectAPI({event}) {
  let data = {id_project: Number(event)};
  return await axios.post('/project/registrationforproject.php', JSON.stringify(data));
}

export async function subscribeEventAPI({event}) {
  let data = {id_event: Number(event)};
  return await axios.post('/event/registrationforevent.php', JSON.stringify(data));
}

export async function unsubscribeProjectAPI({event}) {
  let data = {id_project: Number(event)};
  return await axios.post('/project/cancelregistration.php', JSON.stringify(data));
}

export async function unsubscribeEventAPI({event}) {
  let data = {id_event: Number(event)};
  return await axios.post('/event/cancelregistration.php', JSON.stringify(data));
}

export async function getPartnersEvents() {
  return await axios.post('/event/getpartnersevents.php');
}

export async function timingProjectsAPI({event}) {
  let data = {id_project: Number(event)};
  return await axios.post('/project/geteventsbyproject.php', JSON.stringify(data));
}

export async function updateClickEvent(data) {
  return await axios.post(
    '/event/updatestatusregistrationuser.php',
    JSON.stringify({...data, status: 3}),
  );
}

export async function getArchiveEventAPI(data) {
  return await axios.post('event/getarchiveevents.php', JSON.stringify({...data}));
}


export async function setViewSecondsVideoAPI({id_event, count}){
    return await axios.post('event/updatevideocount.php', JSON.stringify({id_event, count}));
}