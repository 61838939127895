import config from 'config.json';
import axios from 'axios';

export async function isValidNumber(number) {
  let format_number = Number(number);
  return await axios({
    url: config.root + '/user/checkphoneindb.php',
    method: 'POST',
    headers: config.headers,
    data: JSON.stringify({phone_number: format_number}),
  });
}

export async function isAuth({number, code}) {
  let format_number = Number(number);
  let format_code = Number(code);
  return await axios({
    url: config.root + '/user/auth.php',
    method: 'POST',
    headers: config.headers,
    data: JSON.stringify({phone_number: format_number, code: format_code}),
  });
}

export async function sendSMS(number) {
  let format_number = Number(number);
  return await axios({
    url: config.root + '/confirmationbysms/sendsms.php',
    method: 'POST',
    headers: config.headers,
    data: JSON.stringify({phone_number: format_number}),
  });
}
