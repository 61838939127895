import React, {useState} from 'react';
import {Form, Row, Col, Button, Checkbox, Typography, Input, DatePicker} from 'antd';
import {IndividualAccountSelect} from 'Base/Inputs';
import {CityInputSelect} from 'Base/Inputs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'moment/locale/ru';

export function FirstVariant({questions, loading}) {
  const [checkAggree, setCheckAggree] = useState(true);

  let form_field = questions.first_form;

  return (
    <>
      <Typography.Title level={4}>{form_field.inn.title}</Typography.Title>
      <Form.Item name='inn' rules={[
        {required: true, message: 'Поле не заполнено'},
        {validator: (r, v) => {
          if((v.length === 12 || v.length === 10)){
            return Promise.resolve()
          }
          return Promise.reject('Некорректный ИНН')
        }}
      ]}>
        <IndividualAccountSelect onChange={(d) => console.log(d)} />
      </Form.Item>

      <Row gutter={16}>
        <Col xs={{span: 24}} sm={{span: 8}}>
          <Typography.Title level={4}>{questions.surname.title}</Typography.Title>
          <Form.Item
            name='surname'
            rules={[
              {required: true, message: 'Фамилия не заполнена'},
              {
                pattern: /^([А-ЯЁа-яё]*)$/iu,
                message: 'Допустимы символы только в русском алфавите',
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 8}}>
          <Typography.Title level={4}>{questions.name.title}</Typography.Title>
          <Form.Item
            name='name'
            rules={[
              {required: true, message: 'Имя не заполнено'},
              {
                pattern: /^([А-ЯЁа-яё]*)$/iu,
                message: 'Допустимы символы только в русском алфавите',
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 8}}>
          <Typography.Title level={4}>{questions.last_name.title}</Typography.Title>
          <Form.Item
            name='last_name'
            rules={[
              {required: true, message: 'Отчество не заполнено'},
              {
                pattern: /^([А-ЯЁа-яё]*)$/iu,
                message: 'Допустимы символы только в русском алфавите',
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Typography.Title level={4}>{questions.birthday.title}</Typography.Title>
      <Form.Item name='birthday' rules={[{required: true, message: 'Дата рождения не указана'}]}>
        <DatePicker
          locale={locale}
          placeholder='дд-мм-гггг'
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DD.MM.YYYY']}
          style={{width: '100%'}}
        />
      </Form.Item>

      <Typography.Title level={4}>{questions.phone_number.title}</Typography.Title>
      <Form.Item
        name='phone_number'
        validateTrigger='onBlur'
        rules={[
          {required: true, message: 'Телефон не указан'},
          {pattern: /^9[0-9]{9}$/, message: 'Неккоректно указан телефон'},
        ]}>
        <Input addonBefore={'+7'} />
      </Form.Item>

      <Typography.Title level={4}>{questions.city.title}</Typography.Title>
      <Form.Item
        name='city'
        validateTrigger='onBlur'
        rules={[{required: true, message: 'Город не указан'}]}>
        <CityInputSelect />
      </Form.Item>

      <Typography.Title level={4}>{questions.email.title}</Typography.Title>
      <Form.Item
        name='email'
        validateTrigger='onBlur'
        rules={[
          {required: true, message: 'Email не указан'},
          {
            pattern: /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: 'Неккоректно указан email',
          },
        ]}>
        <Input />
      </Form.Item>

      {/* <Form.Item name="aggreement_one" validateTrigger="onBlur">
                <Checkbox onChange={e => {setCheckAggree(!e.target.checked); }} checked={!checkAggree}>
                    <Typography.Paragraph
                        style={{display: 'inline', marginLeft: '5px', color: "black"}} >
                        Даю согласие на обработку и 
                        хранение &nbsp;
                        <a target="_blank" 
                            rel="noopener noreferrer" 
                            href="https://isbusiness.ru/agreeperconaldatabiz.pdf">
                                персональных данных
                        </a>
                    </Typography.Paragraph>
                </Checkbox>
            </Form.Item>

            <Typography.Title level={4}>{questions.interests.title}</Typography.Title>
            <Form.Item name="interest" rules={[ {required: true, message: "Выберите хотя бы одно направление"} ]}>
                <Select mode="multiple">
                    {questions.interests.fields.map((el) => {
                        return <Select.Option key={el.value} value={el.value}>{el.title}</Select.Option>    
                    })}
                </Select>
            </Form.Item>

            <Typography.Title level={4}>{form_field.usefull.title}</Typography.Title>
            <Form.Item  name="usefull" rules={[{required: true, message: "Вы не ответили на вопрос"}]}>
                <Radio.Group>
                    <Radio.Button className="form_corners_left" value={1}>Да</Radio.Button>
                    <Radio.Button className="form_corners_right" value={0}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Typography.Title level={4}>{form_field.gov_info_help.title}</Typography.Title>
            <Form.Item name="gov_info_help" rules={[{required: true, message: "Вы не ответили на вопрос"}]}>
                <Radio.Group>
                    <Radio.Button className="form_corners_left" value={1}>Да</Radio.Button>
                    <Radio.Button className="form_corners_right" value={0}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Typography.Title level={4}>{form_field.is_training.title}</Typography.Title>
            <Form.Item name="is_training" rules={[{required: true, message: "Вы не ответили на вопрос"}]}>
                <Radio.Group>
                    <Radio.Button className="form_corners_left" value={1}>Да</Radio.Button>
                    <Radio.Button className="form_corners_right" value={0}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Typography.Title level={4}>{form_field.is_invite_event.title}</Typography.Title>
            <Form.Item name="is_invite_event" rules={[{required: true, message: "Вы не ответили на вопрос"}]}>
                <Radio.Group>
                    <Radio.Button className="form_corners_left" value={1}>Да</Radio.Button>
                    <Radio.Button className="form_corners_right" value={0}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Typography.Title level={4}>{form_field.learning_topics.title}</Typography.Title>
            <Form.Item name="learning_topics" rules={[{required: true, message: "Выберите интересующие темы"}]}>
                <Checkbox.Group>
                    {form_field.learning_topics.fields.map((el) => {
                        return <Row key={el.value} style={margin.bottom}><Checkbox value={el.value}>{el.title}</Checkbox></Row>
                    })}
                </Checkbox.Group>
            </Form.Item> */}

      <Form.Item>
        <Row>
          <Col sm={{span: 8}} xs={{span: 24}}>
            <Button
              disabled={checkAggree}
              loading={loading}
              style={{width: '100%', marginBottom: '1em'}}
              size={'large'}
              type='primary'
              htmlType='submit'>
              Отправить ответы
            </Button>
          </Col>
          <Col sm={{span: 16}} xs={{span: 24}} style={{paddingLeft: '1em'}}>
            <Form.Item name='aggreement'>
              <Checkbox
                onChange={(e) => {
                  setCheckAggree(!e.target.checked);
                }}
                checked={!checkAggree}>
                <Typography.Paragraph style={{display: 'inline', color: 'black'}}>
                  Нажимая кнопку «Отправить ответы» вы даете согласие на обработку &nbsp;
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://isbusiness.ru/agreeperconaldatabiz.pdf'>
                    персональных данных
                  </a>
                </Typography.Paragraph>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
}
