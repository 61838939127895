import React, {useState, useEffect} from 'react';
import {Typography, Select, Skeleton} from 'antd';
import axios from 'axios';

export default function IndividualAccountSelect(props) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  let timeout = null;

  useEffect(() => {
    if (props.defaultValue || props.value) {
      setLoading(true);
      let value = props.defaultValue || props.value;
      searchOrganization(String(value));
    }
  }, []);

  function onChange(value) {
    if (props.onChange === 'function') {
      props.onChange(value);
    }
  }

  function searchOrganization(value) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    onChange(value);

    function API() {
      axios('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Token 09149ae33eb6cc21c624536e65b47d64885e07ab',
        },
        data: JSON.stringify({query: value}),
      }).then((res) => {
        setList(res.data.suggestions);
      });
    }
    timeout = setTimeout(API, 300);
    setLoading(false);
  }

  return (
    <>
      {!loading ? (
        <Select
          showSearch
          placeholder='Название организации'
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={searchOrganization}
          notFoundContent={null}
          {...props}>
          {list &&
            list.map((el) => {
              return (
                <Select.Option key={el.data.inn} value={el.data.inn}>
                  <Typography.Text strong>{el.value}</Typography.Text>
                  <br />
                  <Typography.Text type='secondary'>
                    {el.data.inn}, {el.data.address.unrestricted_value}
                  </Typography.Text>
                </Select.Option>
              );
            })}
        </Select>
      ) : (
        <Skeleton.Input size='default' style={{width: '100%'}} active />
      )}
    </>
  );
}
