import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Loader} from 'Base/Loader/Loader';
import {useAuthContext} from '../context';
import {useUserContext} from '../context';
import {useEffectOnce} from 'react-use';

export function PrivateUserComponent({children}) {
  const [checkAuth, setCheckAuth] = useState(false);
  const {isAuthenticated, checkAuthToken, setAuth} = useAuthContext();
  const {infoUser} = useUserContext();

  // document.body.scrollTop = 0;
  // document.documentElement.scrollTop = 0;

  useEffectOnce(() => {
    checkAuthToken()
      .then((res) => {
        if (res.data.valid_token) {
          setAuth(true);
          infoUser().then(() => {
            setCheckAuth(true);
          });
        }
      })
      .catch((err) => {
        setAuth(false);
      });
  });

  return isAuthenticated ? checkAuth ? <>{children}</> : <Loader /> : <Redirect to='/signin/' />;
}
