import React from 'react';
import {Row, Col, Typography} from 'antd';
import {MainForm} from './MainForm';

export function Intro() {
  return (
    <div className='wrapper__container'>
      <Row className='wrapper__text'>
        <Col sm={{span: 18, offset: 3}} xs={{span: 24}}>
          <Typography.Title level={2}>Заполните анкету</Typography.Title>
        </Col>
      </Row>
      <Row className='wrapper__text'>
        <Col sm={{span: 18, offset: 3}} xs={{span: 24}}>
          <Typography.Paragraph>Дорогой друг!</Typography.Paragraph>
          <Typography.Paragraph>
            Предпринимательство – это не просто деятельность, заключающаяся в систематическом
            получении прибыли на свой страх и риск. Это образ жизни, связанный со способностью
            человека брать на себя ответственность за собственную жизнь, семью, близкое окружение,
            команду.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row className='wrapper__text'>
        <Col sm={{span: 18, offset: 3}} xs={{span: 24}}>
          <Typography.Title level={4}>Заполнив анкету, вы:</Typography.Title>
          <ul style={{marginLeft: '1em'}}>
            <li style={{marginBottom: '1em'}}>
              получите самую свежую и актуальную информацию для эффективного ведения бизнеса и
              развития предпринимательских компетенций;
            </li>
            <li style={{marginBottom: '1em'}}>
              станете членом сообщества предпринимателей, которые развиваются и выстраивают ценные
              связи, ищут новые возможности и реализуют их.
            </li>
          </ul>
        </Col>
      </Row>
      <MainForm></MainForm>
    </div>
  );
}
