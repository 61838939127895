import React, {useState, useEffect} from 'react';
import {Progress, Tooltip, Card, Typography, Space, Button, Avatar, Row, Col} from 'antd';
import {Panel} from '../Layout';
import {AfficheList} from '../Affiche/AfficheList';
import {Link} from 'react-router-dom';
import {useUserContext} from 'Client/config/context';
import {getPastUserEventsAPI} from './UserAPI';
import {shopItemsAPI} from '../Shop/ShopAPI';
import {UserOutlined} from '@ant-design/icons';

function CardBox({src, background, order, title}) {
  let bg = background || '#F0F3F8';
  return (
    <Col xs={{span: 12, order: 2}} md={{span: 6, order: 1}}>
      <Card
        style={{
          background: `${bg} url(${src}) no-repeat`,
          backgroundSize: 'contain',
          height: '100%',
          position: 'relative',
          minHeight: '195px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}>
        <Typography>{title}</Typography>
      </Card>
    </Col>
  );
}

function UserProgress() {
  const {user} = useUserContext();
  const [fromItem, setFromItem] = useState(null);
  const [toItem, setToItem] = useState(null);
  const [percentProgress, setPercentProgress] = useState(0);

  useEffect(() => {
    shopItemsAPI({limit: 50, offset: 0}).then((res) => {
      let items = res.data;
      let from_item = null;
      let to_item = null;

      for (let i = 0; i < items.length; i++) {
        if (user && items.length >= 2 && Number(user.score) < Number(items[i].cost_balls)) {
          to_item = items[i];
          if (!(i - 1 < 0)) {
            from_item = items[i - 1];
            setPercentProgress(
              ((user.score - from_item.cost_balls) /
                Number(to_item.cost_balls - from_item.cost_balls)) *
                100,
            );
          } else {
            setPercentProgress(Number(user.score / to_item.cost_balls) * 100);
          }
          break;
        }
      }
      setFromItem(from_item);
      setToItem(to_item);
    });
  }, [user]);
  return (
    <Row gutter={20} style={{marginTop: '50px'}}>
      <Col xs={24} md={10} className='user__board'>
        <Space className='user__board_score' direction='vertical' style={{}} align='center'>
          <Typography.Title style={{margin: '0'}} level={1}>
            {user && user.score} баллов
          </Typography.Title>
        </Space>
        <div className='user__board_info' style={{width: '100%', display: 'flex'}}>
          <Button className='user__board_info-item' style={{width: '100%', marginRight: '1em'}}>
            <Link to='/user/privilege/'>Привилегии</Link>
          </Button>
          <Button className='user__board_info-item' style={{width: '100%', marginRight: '1em'}}>
            <Link to='/user/setting/'>Мои данные</Link>
          </Button>
          <Button className='user__board_info-item' style={{width: '100%', marginRight: '1em'}}>
            <Link to='/user/courses/'>Мои курсы</Link>
          </Button>
          {/* <Button className='user__board_info-item' style={{width: '100%'}}>Пригласить друга</Button> */}
        </div>
      </Col>
      {user && (
        <>
          <Col xs={24} md={14}>
            <Row gutter={20} style={{height: '100%'}}>
              {fromItem && <CardBox title={fromItem.name} src={fromItem.images} />}
              <Col
                xs={{span: 24, order: 1}}
                md={{span: 12, order: 1}}
                style={{display: 'flex', alignItems: 'center'}}>
                <Tooltip title={user.score}>
                  <Progress
                    showInfo={false}
                    percent={!percentProgress ? percentProgress + 1 : percentProgress}
                  />
                </Tooltip>
              </Col>
              {toItem && <CardBox title={toItem.name} src={toItem.images} />}
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
}

export function UserPreview() {
  const {user} = useUserContext();

  return (
    <div style={{margin: '50px auto'}}>
      <Space style={{width: '100%'}} direction='vertical' align='center'>
        <Avatar
          src={user && user.avatar}
          size={200}
          icon={<UserOutlined />}
          style={{background: '#F0F3F8', color: '#2e96fe'}}
        />
        <Typography.Title level={4} style={{margin: '0'}}>
          {user && user.surname}
        </Typography.Title>
        <Typography.Title level={4} style={{margin: '0'}}>
          {user && (
            <>
              {user.name} {user.last_name}
            </>
          )}
        </Typography.Title>
      </Space>
      <UserProgress />
      <Panel title='Прошедшие мероприятия'>
        <AfficheList postLoad customRequest={getPastUserEventsAPI} />
      </Panel>
    </div>
  );
}
