import axios from 'axios';
import config from 'config.json';
import {message} from 'antd';

export default (function (token_storage) {
  let instance = axios.create({
    baseURL: config.root,
    headers: {...config.headers},
    validateStatus: function (status) {
      return status >= 200 && status <= 300;
    },
  });

  instance.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        const existingTokens = JSON.parse(localStorage.getItem(token_storage));
        if (existingTokens) config.headers.Authorization = `Bearer ${existingTokens.token}`;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    },
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (!err.response) {
        console.error('INTERCEPTOR: ', err);
      } else if (err.response.status === 401) {
        message.error('Перезайдите в систему. Ошибка авторизации');
        console.error('SUCCESS INTERCEPTOR: ', err);
        localStorage.clear(token_storage);
        window.location.reload();
      }
      return Promise.reject(err);
    },
  );

  return instance;
})('token');
