import React, {useState, useEffect, useRef} from 'react';
import {Typography, Row, Col, Divider, Space, Skeleton, Button, message, Form, Card, Input} from 'antd';
import {useRouteMatch, Link} from 'react-router-dom';
import {formatPeriondicTime} from './utils/functions.js';
import {Loader} from 'Base/Loader/Loader';
import {useUserContext} from 'Client/config/context';
import {formatDate} from './utils/functions';
import YouTube from 'react-youtube';
import socket from 'Client/config/socket';

import {
  getIsRegisterProjectAPI,
  getDetailProjectAPI,
  getIsRegisterEventAPI,
  getDetailEventAPI,
  subscribeProjectAPI,
  unsubscribeProjectAPI,
  timingProjectsAPI,
  updateClickEvent,
  setViewSecondsVideoAPI,
  sendToCHATGPT
} from './API';
import {Room} from '../Chat/Chat';

function EventBoxSkeleton() {
  return (
    <Skeleton.Avatar className='autosize-container' active={true} shape='square'></Skeleton.Avatar>
  );
}

function ScoreTag({score}) {
  if (score.give_balls) {
    let result = !+score.give_balls ? +score.give_balls - +score.cost_balls : +score.give_balls;
    if (result > 0) {
      return <div className='tag--green'>+{result} баллов</div>;
    } else if (result < 0) {
      return <div className='tag--yellow'>{result} баллов</div>;
    }
  }
  return null;
}

export function DetailAffiche({rootUrl}) {
  const [subEvent, setSubEvent] = useState(false);
  const [onLoadVideo, setOnLoadVideo] = useState(false);
  const [loadPage, setLoadPage] = useState(false);
  const [timingProject, setTimingProject] = useState(null);
  const [loadRegitration, setLoadRegistration] = useState(false);
  const [event, setEvent] = useState(null);
  const [once, setOnce] = useState(false);
  const match = useRouteMatch();
  const {setTheme} = useUserContext();

  let styleButton = useRef(null);
  let styleText = useRef(null);

  const display = onLoadVideo ? 'block' : 'none';

  const opts = {
    playerVars: {
      autoplay: 0,
    },
  };

  let start_time = useRef(null);
  let intervalID = useRef(null);

  function parseVideo(url){
    if(url && typeof url === 'string'){
      return url.split('/').pop();
    }
  }
  
  useEffect(() => {
    socket.open()
    return () => {
      socket.close()
    }
  }, [])

  useEffect(() => {
    setTheme('secondary');
    setLoadPage(true);
    if (rootUrl === 'project') {
      Promise.all([
        getIsRegisterProjectAPI({event: match.params.id}),
        getDetailProjectAPI({event: match.params.id}),
        timingProjectsAPI({event: match.params.id}),
      ]).then((res) => {
        setSubEvent(res[0].data.status);
        res[1].data.video = parseVideo(res[1].data.video);
        if(res[1].data.custom_fields && res[1].data.custom_fields !== ""){
          res[1].data.custom_fields = JSON.parse(res[1].data.custom_fields)
          styleButton.current = {
            background: res[1].data.custom_fields['color-event-name text'],
            borderColor: res[1].data.custom_fields['color-event-name text'],
            color: 'white',
            ':active': {
              background: 'white'
            }
          }
          styleText.current = {
            color: res[1].data.custom_fields['color-event-name text']
          }
        }else{
          styleButton.current = null
          styleText.current = null
        }
        setEvent(res[1].data);
        setTimingProject(res[2].data);
        setLoadPage(false);
      });
    } else {
      Promise.all([
        getIsRegisterEventAPI({event: match.params.id}),
        getDetailEventAPI({event: match.params.id}),
      ]).then((res) => {
        setSubEvent(res[0].data.status);
        res[1].data.video = parseVideo(res[1].data.video)
        if(res[1].data.custom_fields && res[1].data.custom_fields !== ""){
          res[1].data.custom_fields = JSON.parse(res[1].data.custom_fields)
          styleButton.current = {
            background: res[1].data.custom_fields['color-event-name text'],
          }
          styleText.current = {
            color: res[1].data.custom_fields['color-event-name text']
          }
        }else{
          styleButton.current = null
          styleText.current = null
        }
        setEvent(res[1].data);
        setTimingProject(null);
        setLoadPage(false);
      });
    }

    return () => {
      setTheme('primary');
    };
  }, [match.params.id, rootUrl]);

  function subscribeEvent() {
    setLoadRegistration(true);
    let id = event.id_project || event.id;
    if (!subEvent) {
      subscribeProjectAPI({event: id}).then((res) => {
        if (res.data.status === 'error') {
          message.error(res.data.message);
        } else if (res.data.status === 'success') {
          message.success(res.data.message);
          setSubEvent(true);
        } else {
          setSubEvent(false);
        }
        setLoadRegistration(false);
      });
    } else {
      unsubscribeProjectAPI({event: id}).then((res) => {
        setSubEvent(false);
        setLoadRegistration(false);
      });
    }
  }

  function clickLink(id) {
    updateClickEvent({id_event: id}).then((res) => {
      console.log(res.data);
    });
  }

  function sendCountAPI(){
    let end = new Date();
    let count = (end - start_time.current) / 1000;
    setViewSecondsVideoAPI({id_event: event.id, count}).catch(err => console.log(err))
    start_time.current = new Date();
  }

  function onPlay(e){
    start_time.current = new Date();
    console.log("PLAY");
    if(!once){
      setOnce(true);
      setViewSecondsVideoAPI({id_event: event.id, count: 0}).catch(err => console.log(err))
      console.log("ONCE");
    }
    intervalID.current = setInterval(sendCountAPI, 5000);
    console.log(intervalID.current);
  }

  function onPause(e){
    console.log("PAUSE");
    let end = new Date();
    let count = (end - start_time.current) / 1000;
    setViewSecondsVideoAPI({id_event: event.id, count}).catch(err => console.log(err))
    console.log(intervalID.current);
    clearInterval(intervalID.current);
  }

  function onEnd(e){
    console.log("END");
    let end = new Date();
    let count = (end - start_time.current) / 1000;
    setViewSecondsVideoAPI({id_event: event.id, count}).catch(err => console.log(err))
    clearInterval(intervalID.current);
  }

  console.log(event);

  return (
    <div>
      {loadPage && <Loader />}
      <div
        className='affiche__detail_header color__font-white'
        style={{
          backgroundImage: `url(${
            event ? event.cover : null
          }), linear-gradient(to right, rgba(0,0,0, 0.8), rgba(0,0,0, 0.3))`,
          backgroundBlendMode: 'overlay',
        }}>
        {event && event.cost_balls !== undefined ? (
          <ScoreTag
            style={{margin: '0 0 1em 0'}}
            score={{cost_balls: event.cost_balls, give_balls: event.give_balls}}
          />
        ) : null}

        <Typography.Title level={1} style={{margin: '0 0 10px 0'}}>
          {event ? event.name : null}
        </Typography.Title>
        <Typography.Title level={4} style={{margin: '0'}}>
          {event ? event.location : null}
        </Typography.Title>
        {/* {event && event.show_seat && event.real_seat && event.count_registration && (
          <Typography.Title level={4}>
            {`Количество мест: ${
              Math.round(event.show_seat / event.real_seat) * event.count_registration
            }/${event.show_seat}`}
          </Typography.Title>
        )} */}
        <Space>
          <Button
            loading={loadRegitration}
            style={styleButton.current}
            type={subEvent ? 'secondary' : 'primary'}
            onClick={() => subscribeEvent()}
            size='large'>
            {subEvent ? 'Отменить участие' : 'Участвовать'}
          </Button>
          {event && event.url_stream && (
            <Button
              onClick={() => clickLink(event.id)}
              type='link'
              size='large'
              href={event.url_stream}>
              Перейти по ссылке
            </Button>
          )}
        </Space>
      </div>
      <div style={{margin: '50px auto'}}>
        <Row gutter={[20, 20]} className='affiche__detail_container'>
          <Col xs={24} md={12}>
            {timingProject && (
              <>
                <Typography.Title level={2}>Расписание</Typography.Title>
                {timingProject.map((el) => {
                  return (
                    <>
                      <Row>
                        <Col xs={24} md={12}>
                          <Typography.Title level={4} >
                            <Link to={`/event/${el.id}`} style={styleText.current}>{el.name}</Link>
                          </Typography.Title>
                          <Typography.Text>Спикер: {el.speakers}</Typography.Text>
                          <br />
                          <Typography.Text>Место: {el.short_location}</Typography.Text>
                        </Col>
                        <Col xs={12} md={{span: 8, offset: 4}}>
                          <Typography.Title level={4}>
                            {el.date_time_start ? formatDate({begin: el.date_time_start}) : null}
                          </Typography.Title>
                          <p>{formatPeriondicTime(el.date_time_start, el.date_time_end)}</p>
                          {/* <p>{`${Math.round(el.show_seat / el.real_seat) * el.count_registration}/${
                            el.show_seat
                          }`}</p> */}
                        </Col>
                      </Row>
                      <Divider style={{marginTop: '0'}} />
                    </>
                  );
                })}
              </>
            )}

            <Typography.Title level={2}>О мероприятии</Typography.Title>
            {event ? (
              <p
                dangerouslySetInnerHTML={{__html: event.description}}
                style={{whiteSpace: 'pre-wrap'}}
              />
            ) : null}
          </Col>
          
          <Col xs={24} md={12} style={{width: '100%'}}>
            <Typography.Title level={2}>Видео</Typography.Title>
            <div style={{display: display}} className='autosize-container'>
              <YouTube videoId={event && event.video} opts={opts}
                
                onReady={() => setOnLoadVideo(true)} onEnd={onEnd} onPlay={onPlay} onPause={onPause} />
            </div>
            {!onLoadVideo ? <EventBoxSkeleton /> : null}
            
          </Col>
          {event?.is_last && 
            <Col xs={24} md={{offset: 4, span: 16}}>
              <Card size='small' title='Виртуальный помощник'>
                <Typography.Paragraph>
                Я помогу обобщить и систематизировать знания, полученные на программе обучения. 
                Вам нужно предоставить информацию и данные, которые я могу использовать для каждого раздела вашего бизнес-плана. 
                Вот что мне потребуется для максимально полного и качественного выполнения задания:
                </Typography.Paragraph>
                <Form layout='vertical' id='wish_form' onFinish={(d) => sendToCHATGPT(d)}>
                  <Form.Item>
                    <Card size='small' title='Титульный лист'>
                        <Form.Item name='general_fio' label='ФИО'>
                          <Input />
                        </Form.Item>
                        <Form.Item name='general_phone' label='Телефон'>
                          <Input />
                        </Form.Item>
                        <Form.Item name='general_email' label='Почта'>
                          <Input />
                        </Form.Item>
                        <Form.Item name='general_name_project' 
                          label='Наименование проекта' 
                          help='Например, кафе "ZERNO" или салон красоты "Белоснежка"'
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item name='general_start_region' 
                          label='Территория запуска бизнеса' 
                          help='Например, Красноярск или Российская федерация'
                        >
                          <Input />
                        </Form.Item>
                    </Card>
                  </Form.Item>
                  <Form.Item>
                    <Card size='small' title='Резюме'>
                      <Form.Item name='resume_target' 
                          label='Опишите ключевые цели и задачи вашего проекта' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='resume_cost' 
                          label='Напишите общую сумму инвестиций в проект и основные направления их использования' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                    </Card>
                  </Form.Item>
                  <Form.Item>
                    <Card size='small' title='Описание проекта'>
                      <Form.Item name='description_project' 
                          label='Сделайте подробное описание видения вашего проекта' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='description_unique_and_concurent' 
                          label='Опишите уникальные торговые предложения и конкурентные преимущества' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='description_power' 
                          label='Опишите планируемые мощности и объемы услуг или производства' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                    </Card>
                  </Form.Item>
                  <Form.Item>
                    <Card size='small' title='Анализ рынка'>
                      <Form.Item name='analyze_audience' 
                          label='Предоставьте данные о целевой аудитории и ее потребностях' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='analyze_concurents' 
                          label='Напишите информацию о конкурентах, их сильных и слабых сторонах' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='analyze_economic' 
                          label='Опишите экономическую ситуацию в регионе и специфику рынка вашего сегмента' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                    </Card>
                  </Form.Item>
                  <Form.Item>
                    <Card size='small' title='Маркетинговый план'>
                      <Form.Item name='marketing_audience' 
                          label='Опишите предпочтения и поведение вашей целевой аудитории' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='marketing_advertisement' 
                          label='Опишите планы на рекламу и продвижение, включая бюджет и каналы коммуникации' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='marketing_cost' 
                          label='Опишите ценовую стратегию и ценообразование' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                    </Card>
                  </Form.Item>
                  <Form.Item>
                    <Card size='small' title='План реализации проекта'>
                      <Form.Item name='plan_realize' 
                          label='Напишите предполагаемые сроки и этапы реализации проекта' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='plan_resource' 
                          label='Напишите необходимые ресурсы и условия для их обеспечения' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                    </Card>
                  </Form.Item>
                  <Form.Item>
                    <Card size='small' title='Финансовый план'>
                      <Form.Item name='finanse_description' 
                          label='Напишите предполагаемый бюджет проекта, включая статьи расходов и доходов' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='finanse_source' 
                          label='Предоставьте информацию о возможных источниках финансирования' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='finanse_money_stream' 
                          label='Какие финансовые потоки, включая точку безубыточности и период окупаемости вы прогнозируете' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                    </Card>
                  </Form.Item>
                  <Form.Item>
                    <Card size='small' title='Риски проекта и меры по их минимизации'>
                      <Form.Item name='risk_description' 
                          label='Дайте оценку потенциальных внешних и внутренних рисков' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                      <Form.Item name='risk_offer' 
                          label='Предложите план по минимизации рисков и управлению ими' 
                        >
                          <Input.TextArea />
                        </Form.Item>
                    </Card>
                  </Form.Item>
                  <Form.Item>
                    <Space>
                      <Button htmlType='submit' form='wish_form'>ПОЛУЧИТЬ БИЗНЕС-ПЛАН</Button>
                      <Typography>Бизнес-план придёт на указанную вами почту в течении 24-х часов</Typography>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          }
          {!event?.is_last &&
            <Col xs={24} md={{offset: 8, span: 10}}>
              {rootUrl === 'event' && <>
                  <Typography.Title level={2}>Чат</Typography.Title>
                  <div  style={{height: '450px'}}>
                    <Room title={event && event.name} room='course' data={match.params.id} isBack={false} />
                  </div>
                </>
              }
            </Col>
          }
          
        </Row>
      </div>
    </div>
  );
}
