import React, {useState} from 'react';
import {Layout, Divider, Typography, Space, Avatar, Drawer} from 'antd';
import {NavLink, Link} from 'react-router-dom';
import {MenuOutlined, CloseOutlined, UserOutlined} from '@ant-design/icons';
import {useUserContext} from '../../../config/context';
import '../../style.css';

const {Header} = Layout;

export default function BaseHeader() {
  const [visible, setVisible] = useState(false);
  const {user, theme} = useUserContext();

  function render() {
    return (
      <Header
        className={`fluid-container ${theme === 'secondary' ? 'color__font-white' : ''} header`}
        style={theme === 'secondary' ? {marginBottom: '-80px'} : null}>
        <div style={{display: 'inherit'}}>
          <Link to='/'>
            {/* <img alt='test' src={theme === 'primary' ? '/primary-brand.svg' : '/logo1.png'}></img> */}
            <img alt='logo' src={'/secondary-brand.svg'}></img>
          </Link>
        </div>
        {user && (
          <>
            <Space className='navigation__desktop'>
              <nav className='navbar'>
                <NavLink exact activeStyle={{color: '#096dd9'}} to='/' className='navbar__item'>
                  Главная
                </NavLink>
                <NavLink
                  exact
                  activeStyle={{color: '#096dd9'}}
                  to='/events/'
                  className='navbar__item'>
                  Мои мероприятия
                </NavLink>
                <NavLink
                  exact
                  activeStyle={{color: '#096dd9'}}
                  to='/shop/course/'
                  className='navbar__item'>
                  Магазин
                </NavLink>
                <NavLink
                  exact
                  activeStyle={{color: '#096dd9'}}
                  to='/user/privilege/'
                  className='navbar__item'>
                  Привилегии
                </NavLink>
              </nav>
              <Divider type='vertical' style={{margin: '0 10px 0 10px'}} />
              <p style={{margin: '0'}}>{user.score} баллов</p>
              <Link to='/user/'>
                <Avatar
                  src={user.avatar}
                  icon={<UserOutlined />}
                  style={{
                    cursor: 'pointer',
                    color: '#2e96fe',
                    margin: '0 0 0 22px',
                    backgroundColor: '#F0F3F8',
                  }}
                />
              </Link>
            </Space>
          </>
        )}

        <Space className='navigation__mobile'>
          <MenuOutlined style={{fontSize: '2em'}} onClick={() => setVisible(true)} />
          <Drawer
            closable={false}
            visible={visible}
            width='100%'
            title={
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{display: 'inherit'}}>
                  <img alt='test' src='/primary-brand.svg'></img>
                </div>
                <CloseOutlined
                  style={{fontSize: '2em'}}
                  onClick={() => {
                    setVisible(false);
                  }}
                />
              </div>
            }>
            <Space style={{width: '100%'}} direction='vertical' align='center'>
              <Link
                to='/user/'
                onClick={() => {
                  setVisible(false);
                }}>
                <Avatar
                  size={100}
                  src={user && user.avatar}
                  icon={<UserOutlined />}
                  style={{background: '#F0F3F8', color: '#2e96fe'}}
                />
              </Link>
              <Typography.Title level={4} style={{margin: '0'}}>
                {user && user.surname}
              </Typography.Title>
              <Typography.Title level={4}>
                {user && user.name} {user && user.last_name}
              </Typography.Title>
              <Typography.Title level={4}>{user && user.score} баллов</Typography.Title>
            </Space>
            <Divider type='horizontal' />
            <nav
              className='navbar'
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              onClick={() => {
                setVisible(false);
              }}>
              <Link to='/' className='navbar__item'>
                <p>Главная</p>
              </Link>
              <Link to='/user/' className='navbar__item'>
                <p>Личный кабинет</p>
              </Link>
              <Link to='/events/' className='navbar__item'>
                <p>Мои мероприятия</p>
              </Link>
              {/* <Link to='/shop/course/' className='navbar__item'>
                <p>Магазин</p>
              </Link>
              <Link to='/user/privilege/' className='navbar__item'>
                <p>Привилегии</p>
              </Link> */}
            </nav>
          </Drawer>
        </Space>
      </Header>
    );
  }
  return render();
}
