import React, {useState, useEffect} from 'react';
import {Col, Row, Button} from 'antd';
import {useList} from 'react-use';
import {EventCard} from 'Base/Card';

import {getPersonalCoursesAPI} from './API';

export function PersonalCourseList() {
  const limit = 9;
  const [courses, {push}] = useList([]);
  const [offset, setOffset] = useState(0);
  const [postLoad, setPostLoad] = useState(true);

  useEffect(() => {
    getPersonalCoursesAPI({limit: limit, offset: offset}).then((res) => {
      if (res.data.length < limit) setPostLoad(false);
      push(...res.data);
    });
  }, [offset]);

  return (
    <>
      <Row gutter={[20, 20]}>
        {courses.map((el) => {
          return (
            <Col key={el.id} md={8} sm={12} xs={24}>
              <EventCard url={`/course/${el.id}`} img={el.image} title={el.name} />
            </Col>
          );
        })}
      </Row>
      {postLoad && (
        <Button size='large' style={{width: '100%'}} onClick={() => setOffset(offset + 9)}>
          Загрузить еще
        </Button>
      )}
    </>
  );
}