import React from 'react';
import {Route} from 'react-router-dom';
import {PrivateUserComponent} from './PrivateComponents';

export function PrivateRoute({children, ...rest}) {
  return (
    <Route
      {...rest}
      render={(match) => <PrivateUserComponent match={match}>{children}</PrivateUserComponent>}
    />
  );
}
