import React, {useState} from 'react';
import {Space, Typography, Form, Button, Row, Col, Alert, message, Layout} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import {isValidNumber, isAuth, sendSMS} from './API';
import {useAuthContext} from 'Client/config/context';
import {InputMaskNumber, InputMaskCode} from 'Base/Inputs';
import '../style.css';
import './style.css';

export function Signin() {
  const [stateNumber, setStateNumber] = useState(null);
  const [number, setNumber] = useState(null);
  const [codeNumber, setCodeNumber] = useState(null);
  const [accessButton, setAccessButton] = useState(true);
  const [processButton, setProcessButton] = useState(false);
  const [checkCodeForm, setCheckCodeForm] = useState(false);
  const history = useHistory();
  const [error, setError] = useState(null);
  const {Authorization} = useAuthContext();

  function reformatNumber(number) {
    setStateNumber(number);
    let format = String(number).replace(/(\+|\(|\)|-|_|\s)/gi, '');
    setNumber(format);
    return format.length === 11 ? setAccessButton(false) : setAccessButton(true);
  }

  function checkCode(code) {
    let format = String(code).replace(/(\s|_)/gi, '');
    setCodeNumber(format);
    if (format.length === 4) {
      setProcessButton('validating');
      isAuth({number: number, code: format}).then((res) => {
        if (!res.data.status) {
          setProcessButton(false);
          return message.error(res.data.message);
        }
        Authorization(res.data.token);
        let redirect = localStorage.getItem('utm');
        if (redirect) {
          localStorage.removeItem('utm');
          return history.push(redirect);
        }
        return history.push('/');
      });
    }
  }

  function checkNumber() {
    setError(null);
    isValidNumber(number).then((res) => {
      if (!res.data.status) {
        console.log('error');
        message.error(res.data.message);
        return;
      }
      setCheckCodeForm(true);
      sendSMS(number).then((res) => {});
    });
  }

  return (
    <Layout
      style={{
        background: 'rgb(247, 248, 250)',
        minHeight: 'inherit',
        justifyContent: 'space-between',
      }}>
      <div className='fluid-container signin'>
        <Row className='signin__container'>
          <Col className='signin__form' xs={24} sm={12} lg={{offset: 2, span: 6}}>
            <Space direction='vertical' className='signin__enter'>
              <Typography.Title level={1}>Вход</Typography.Title>
              <Form>
                {!checkCodeForm ? (
                  <>
                    <i>Номер телефона</i>
                    <Form.Item
                      name='phone_number'
                      validateTrigger='onBlur'
                      rules={[
                        {
                          required: true,
                          pattern: /^(\+7\s\(9[0-9]{2}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2})$/,
                          message: 'неправильно набран номер',
                        },
                      ]}>
                      <InputMaskNumber
                        value={stateNumber}
                        onChange={(e) => reformatNumber(e.target.value)}
                        mask='+7 (999) 999-99-99'
                      />
                    </Form.Item>
                    {error ? <Alert type={error.type} message={error.message} /> : null}
                    <Button
                      disabled={accessButton}
                      onClick={() => checkNumber()}
                      style={{width: '100%', marginBottom: '1em'}}
                      size={'large'}
                      type='primary'
                      htmlType='submit'>
                      Войти
                    </Button>
                  </>
                ) : (
                  <>
                    <i>Введите присланный код</i>
                    <Form.Item hasFeedback validateStatus={processButton} name='check__code'>
                      <InputMaskCode
                        value={codeNumber}
                        onChange={(e) => checkCode(e.target.value)}
                        mask='9  9  9  9'
                        placeholder='_  _  _  _'
                      />
                    </Form.Item>
                    {error ? <Alert type={error.type} message={error.message} /> : null}
                  </>
                )}

                <Typography>
                  Еще нет аккаунта? <Link to='/register/'>Регистрация здесь</Link>
                </Typography>
              </Form>
            </Space>
          </Col>

          <Col className='signin__image' xs={24} sm={12} lg={{offset: 4, span: 12}}>
            <img alt='auth' style={{maxWidth: '530px', width: '100%'}} src='/signup.png'></img>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
