import React, {useState, useEffect} from 'react';
import {Typography, Card, Button, Row, Col, Modal, notification} from 'antd';
import {Loader} from 'Base/Loader/Loader';
import {useUserContext} from 'Client/config/context';
import {shopItemsAPI, buyItemAPI} from './ShopAPI';

export function ShopItem({id, name, price, description, size, src}) {
  const {user, setUserSettings} = useUserContext();
  const [width] = useState(310 * size || 310);
  const [span] = useState(6 * size || 6);
  const [hover, setHover] = useState('none');
  const [availableItem, setAvailableItem] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const successNotificationItem = (type) => {
    notification[type]({
      message: 'Успешная покупка',
      description: `Получить товар ${name} вы можете получить на ближайшем очном мероприятии`,
    });
  };

  useEffect(() => {
    if (user && Number(price) <= user.score) {
      setAvailableItem(true);
    } else {
      setAvailableItem(false);
    }
  }, [user, price]);

  function buyItem(id_item) {
    buyItemAPI({id_item}).then((res) => {
      setUserSettings({...user, score: Number(res.data.balance)});
      setModalVisible(false);
      successNotificationItem('success');
    });
  }

  return (
    <Col xs={2 * span} lg={span}>
      <Card
        hoverable
        onMouseEnter={() => setHover('flex')}
        onMouseLeave={() => setHover('none')}
        style={{
          background: src,
          backgroundPosition: 'center center',
          fontSize: '14px!important',
          textAlign: 'center',
          padding: '0px',
          backgroundSize: '80%',
          width: {width} + 'px',
          height: '310px',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}>
        <div className='card__hover color__font-white' style={{display: hover}}>
          <Typography.Title level={4}>{name}*</Typography.Title>
          <p>{price} баллов</p>
          {user && (
            <Button
              size='large'
              onClick={() => setModalVisible(true)}
              disabled={!availableItem ? true : false}
              type='primary'>
              {availableItem ? 'Обменять баллы' : 'недостаточно баллов'}
            </Button>
          )}
        </div>
        <Typography.Title level={4}>{name}</Typography.Title>
        <Typography>{price} баллов</Typography>
      </Card>
      <Modal
        title={`Купить ${name}`}
        visible={modalVisible}
        onOk={() => buyItem(id)}
        onCancel={() => setModalVisible(false)}
        okText='Купить'
        cancelText='Я передумал :-('>
        <p>С вас спишется {price} баллов</p>
      </Modal>
    </Col>
  );
}

export function Merch() {
  const [shopItems, setShopItems] = useState([]);
  const [loadPage, setLoadPage] = useState(false);
  const [offset, setOffset] = useState(50);
  const [showPreload, setShowPreload] = useState(true);

  useEffect(() => {
    setLoadPage(true);
    shopItemsAPI({limit: 50, offset: 0}).then((res) => {
      if (res.data.length < 50) {
        setShowPreload(false);
      }
      setShopItems(res.data);
      setLoadPage(false);
    });
  }, []);

  function loadShopItems() {
    shopItemsAPI({limit: 8, offset: offset}).then((res) => {
      if (res.data.length < 8) {
        setShowPreload(false);
      }
      setShopItems([...shopItems, ...res.data]);
      setOffset(offset + 8);
    });
  }

  return (
    <div>
      {loadPage && <Loader />}
      <Row gutter={[16, 16]}>
        {shopItems.length
          ? shopItems.map((el) => {
              return (
                <ShopItem
                  key={el.id}
                  id={el.id}
                  name={el.name}
                  price={el.cost_balls}
                  description={el.description}
                  src={`#F0F3F8 url(${el.images}) no-repeat` /* shopItems.images*/}
                />
              );
            })
          : null}
      </Row>
      {showPreload && (
        <Button size='large' style={{width: '100%'}} onClick={() => loadShopItems()}>
          Загрузить еще
        </Button>
      )}
      <i>* - Внешний вид товара может отличатся от изображения в системе</i>
    </div>
  );
}
