import React from 'react';
import {Typography} from 'antd';
import './links.css';

export function LinksMobile() {
  return (
    <div className='mobile_apps'>
      <Typography.Link
        rel='noreferrer'
        target='_blank'
        href='https://play.google.com/store/apps/details?id=info.xorcistexo.isbussiness&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
        <img
          style={{width: '113%', margin: '25px -15px 0 -13px'}}
          alt='Доступно в Google Play'
          src='https://play.google.com/intl/en_us/badges/static/images/badges/ru_badge_web_generic.png'
        />
      </Typography.Link>
      <Typography.Link
        rel='noreferrer'
        target='_blank'
        href='https://apps.apple.com/ru/app/isbusiness/id1537612458'>
        <img
          style={{width: '100%'}}
          alt='Доступно в Google Play'
          src='/static/apps_badge/apple.svg'
        />
      </Typography.Link>
    </div>
  );
}
