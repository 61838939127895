import React from 'react';
import {Layout, Space, Row, Col, Typography, Divider} from 'antd';
import {Link} from 'react-router-dom';
import {useUserContext} from '../../../config/context';
import {YoutubeOutlined, InstagramOutlined, FacebookOutlined} from '@ant-design/icons';
import {VkontakteOutlined} from './icons/VkontakteOutlined';
import './footer.css';

const {Footer} = Layout;

export default function BaseFooter() {
  const {user} = useUserContext();

  return (
    <div style={{background: '#1F232C'}}>
      <Footer className='fluid-container footer color__font-white'>
        <Row gutter={16}>
          <Col md={7} xs={18}>
            <Link to='/'>
              <div className='header__logo-footer' style={{display: 'inherit'}}>
                <img alt='logo' style={{marginBottom: '2em'}} src='/secondary-brand.svg'></img>
              </div>
            </Link>
          </Col>
          {user && (
            <>
              <Col md={4} xs={12}>
                <Space direction='vertical' style={{marginBottom: '2em'}}>
                  <p style={{margin: '0'}}>НАВИГАЦИЯ</p>
                  <Link to='/'>Главная</Link>
                  <Link to='/events/'>Мероприятия</Link>
                  {/* <Link to='/shop/course/'>Магазин</Link> */}
                  <Link to='/user/'>Профиль</Link>
                </Space>
              </Col>
              <Col md={4} xs={12}>
                <Space direction='vertical' style={{marginBottom: '2em'}}>
                  <p style={{margin: '0'}}>АККАУНТ</p>
                  <Link to='/user/'>Личный кабинет</Link>
                  {/* <Link to='/user/privilege/'>Привилегии</Link> */}
                  <Link to='/user/setting/'>Мои данные</Link>
                </Space>
              </Col>
            </>
          )}
          <Col md={4} xs={12}>
            <Space direction='vertical' style={{marginBottom: '2em'}}>
              <p style={{margin: '0'}}>ПРИСОЕДИНЯЙСЯ К НАМ</p>
              <Typography.Link target='_blank' href='https://vk.com/moi.biz.mordovia'>
                <Space align='center'>
                  <VkontakteOutlined style={{fontSize: '1.5em'}} />
                  Вконтакте
                </Space>
              </Typography.Link>
              <Typography.Link
                target='_blank'
                href='https://www.youtube.com/channel/UCDuw5I2DJZCI9Px6BS1O8eQ'>
                <Space align='center'>
                  <YoutubeOutlined style={{fontSize: '1.5em'}} />
                  YouTube
                </Space>
              </Typography.Link>
            </Space>
          </Col>
          <Col md={4} xs={24}>
            <Space direction='vertical' style={{marginBottom: '2em'}}>
              <p style={{margin: '0'}}>ОБРАТНАЯ СВЯЗЬ</p>
              <p>support@isbusiness.ru</p>
            </Space>
          </Col>
        </Row>
        <Divider type='horizontal' style={{background: '#F0F3F8', margin: '0 0 1em 0'}} />
        <Row justify='space-between' className='copyright' align='center'>
          <Typography.Paragraph style={{color: 'white'}}>
            © 2024 Информационная система БИЗНЕС.РФ
          </Typography.Paragraph>
          <Space size={8}>
            <Typography.Link
              rel='noreferrer'
              target='_blank'
              href='https://play.google.com/store/apps/details?id=info.xorcistexo.isbussiness&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img
                style={{width: '110px', marginLeft: '-10px'}}
                alt='Доступно в Google Play'
                src='/static/apps_badge/gplay.png'
              />
            </Typography.Link>
            <Typography.Link
              rel='noreferrer'
              target='_blank'
              href='https://apps.apple.com/ru/app/isbusiness/id1537612458'>
              <img
                style={{width: '100px'}}
                alt='Доступно в Apple Store'
                src='/static/apps_badge/apple.svg'
              />
            </Typography.Link>
          </Space>
        </Row>
      </Footer>
    </div>
  );
}
