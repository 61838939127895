import axios from 'Client/config/axios';

export async function shopItemsAPI({limit, offset}) {
  let data = {limit: Number(limit), offset: Number(offset)};
  return await axios.post('/shop/getproducts.php', JSON.stringify(data));
}

export async function buyItemAPI({id_item}) {
  let data = {id_shop_item: Number(id_item)};
  return await axios.post('/shop/buyitem.php', JSON.stringify(data));
}

//==============COURSE============================

export async function getCourseListAPI(data) {
  if (!data) {
    data = {
      interests: [],
      free_tariff: -1,
    };
  }
  return await axios.post('/course/getlist.php', JSON.stringify(data));
}

//==============PROMOCODE============================

export async function getCoursePromocodeAPI(data) {
  return await axios.post('/course/getlistbypromocode.php', JSON.stringify(data));
}
